<template lang="pug">
div
  home-splash
  home-navigation
  main
    section(id="post").post.py-16
      v-container.container-pd.d-flex.justify-space-between.flex-column.pt-16
        v-row.main
          v-col
            post-card(:data="data")
          v-col
            post-sidebar
  home-footer
</template>

<script>
import updateTitle from "@/mixins/updateTitle";

// components
import HomeSplash from "@/components/splashScreens/HomeSplash";
import HomeNavigation from "@/components/Navigations/HomeNavigation";
import HomeFooter from "@/components/HomeFooter";
import PostSidebar from "@/components/PostSidebar";
import PostCard from "@/components/PostCard";

export default {
  name: "PostView",
  data() {
    return {
      data: null,
    };
  },
  components: {
    HomeSplash,
    HomeNavigation,
    HomeFooter,
    PostCard,
    PostSidebar,
  },
  mixins: [updateTitle],
  created() {
    this.getPost();
    this.updateTitle(this.data.title);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    // Написать метод, который будет запрашивать у бекенда
    // пост по параметру slug ### this.$route.params
    // Сделаю заглушку
    getPost() {
      if (this.$route.params.slug === "why-secret") {
        this.data = {
          link: "why-secret",
          title: "В чём секрет успеха хорошего Telegram-канала?",
          image: "https://seads.me/storage/blog/202208/62f504ff17792.png",
          date: "1 декабря 2021, 12:00",
          views: "1 490",
          description:
            "TGStat недавно провели масштабное исследование, опросив множество \n русскоязычных пользователей мессенджера, и выяснили, что 75% из них читают новости именно в Телеграм. Данное исследование поддержали 900 различных каналов, десятки ботов, а также 7,5 тысячи групп",
          small_description:
            "TGStat недавно провели масштабное исследование, опросив множество русскоязычных пользователей мессенджера, и выяснили, что 75% из них читают новости именно в Телеграм. Данное исследование поддержали 900 различных каналов, десятки ботов, а также 7,5 тысячи групп. Четыре из пяти опрошенных пользователей регулярно читают новостные каналы, чтобы оставаться в курсе актуальных событий в России",
        };
      } else if (this.$route.params.slug === "content-plan") {
        this.data = {
          link: "content-plan",
          title: "Контент-план для Телеграм канала",
          image: "https://seads.me/storage/blog/202208/62f50507ea2cd.png",
          views: "9 692",
          date: "19 декабря 2020, 15:00",
          description:
            "Подобная таблица поможет вам быть более дисциплинированными — у вас будут сроки публикаций под рукой и вы будете знать, сколько постов вы должны подготовить на день/ неделю/ месяц. Также контент-план подразумевает, что у вас всегда будет список тем, а значит и выбор. Конечно, на правах автора, вы можете их варьировать как вздумается, но факт того, что вы продумали темы наперед обезопасит вас от недостатка идей и творческого застоя. Ну а анализ реакций на посты даёт вам возможность повышать вовлечённость аудитории посредствам улучшения самого контента",
          small_description:
            "Большинство интернет-магазинов уже давно поняли насколько сейчас актуальны мессенджеры, и как они повышают количество и качество продаж. Однако, не все ещё осознали, что этика продаж и общения в мессенджерах отличается от других платформ. Мы предлагаем вам разобрать несколько базовых правил коммуникации с клиентами или потенциальными клиентами (это касается как Телеграма, так и Facebook",
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.post {
  background: #f5f5f7;
  min-height: 100vh;
  display: grid;
}

.main {
  & > *:first-child {
    flex: 2;
  }
}

@media screen and (max-width: 1080px) {
  .main {
    flex-direction: column;
  }
}
</style>
