<template lang="pug">
div
  home-navigation
  main.pt-16
    section(id="term").terms.py-16
      v-container.d-flex.justify-space-between.flex-column
        v-card.card.pa-5
          h2.text-center.mb-6 Пользовательское соглашение
          p
            strong Перед тем, как начать использование Сервиса «SEads» (далее – «Сервис»),
          p
            strong просим вас внимательно прочитать этот документ.
          p Используя Сервис с помощью личного Аккаунта на Сайте, а также при нажатии кнопки на Сайте, которая подтверждает согласие с настоящими Правилами, вы принимаете изложенные в этих Правилах условия и обязуетесь соблюдать их. Если вы не принимаете условия Правил, просим вас прекратить пользование Сервисом.
          p Сервиc  Seads.me представляет собой каталог публичных каналов различных тематик, как инструмент для поиска рекламных площадок в экосистеме мессенджера Telegram. Данная платформа объединяет администраторов популярных каналов и рекламодателей, желающих продвинуть свой продукт, канал, бренд или сервис.
          br
          p 
            strong 1. Определение понятий
          p 1.1 В настоящих Правилах понятия и определения используются в следующем значении:
          p 1.1.1 Проект — рекламный пост, оформленный в соответствующей форме и содержащий изображение, ссылку на продукт, сервис, услугу и рекламный текст.
          p 1.1.2 Рекламодатель — пользователь Сервиса, который создает рекламные Проекты, проверяет и оплачивает их выполнение.
          p 1.1.3 Представитель — пользователь Сервиса, владелец или администратор публичного или приватного канала в мессенджере Telegram.
          br 
          p
            strong 2. Создание рекламного Проекта
          p 2.1 Рекламный проект может создавать любой пользователь, зарегистрировавшийся в сервисе или обратившись к нашим менеджерам 
            a(href="https://t.me/Social_Energy") https://t.me/Social_Energy
            | , как Рекламодатель так и Представитель.
          p 2.2 Проект состоит из:
          ul
            li — списка публичных каналов (в которых планируется размещение проекта);
            li — ссылки на проект;
            li — текста рекламной записи;
            li — изображения;
            li — пожеланий к проекту (требования к форматированию поста, дата и время публикации, прочее).
          br
          p 2.3 Менеджер рассматривает заявку на размещение рекламного Проекта во время рабочего времени с 10:00 по 20:00 МСК.
          p 2.4 Проект публикуется в ленте публичных сообщений каналом на 24 часа с удержанием 1 часа в топе (без обновлений новыми постами).
          p 2.5 Проект оплачивается после согласования рекламного поста,рекламной площадки и даты размещения.
          p 2.6 Представитель в праве отменить заявку на Проект только по таким причинам:
          ul
            li — Несоответствие рекламного поста ограничениям указанным Представителем при подключении к Сервису;
            li Если рекламное место занято поверх рек.места Представителя(при условии что место отмечено в графике продаж).
          br
          p 2.7  Рекламодатель имеет право отменить оплату в течении 24 часов после размещении Проекта, в случае если были допущены следующие нарушения:
          ul
            li 2.7.1 Проект не был опубликован в выбранном канале или чате (группе);
            li 2.7.2 Проект был опубликован без изображения;
            li 2.7.3 Ссылка на проект видоизменена или не соответствует заявленной;
            li 2.7.4 Текст рекламной записи был видоизменен без предварительного согласования или не соответствует заявленному;
            li 2.7.5 Проект был опубликован без учета пожеланий Рекламодателя (дата, время, формат поста и прочее);
            li 2.7.6 Канал в котором заказан Проект — заблокирован или неактивен.
            li 2.7.7 Проект находился менее 24 часов в ленте выбранного канала;
            li 2.7.8 Проект находился менее 1 часа в топе ленты постов выбранного канала;
            li 2.7.9 Аудитория канала и её поведение вызывает подозрение (накрутка подписчиков, просмотров и прочая неестественная активность).
          br
          p 2.8 Все иные причины отмены оплаты Рекламодателем могут быть рассмотрены администраторами Сервиса. Финальное решение по таким жалобам принимаются на усмотрение администратора Сервиса.
          p 2.9 Администрация сайта вправе отменить рекламное размещение, если оно несет мошеннический характер (СКАМ). Средства рекламодателя замораживаются для компенсации пострадавшим
          br
          p
            strong 3. Добавление канала 
          p 3.1 Заявку на добавление публичного или приватного канала может подать любой пользователь, как Рекламодатель так и Представитель.
          p 3.2 В каталог Сервиса добавляются как публичные так и приватные каналы. Каналы с блоком на IOS устройствах, тоже принимаются к рассмотрению.
          p 3.3 Заявка на добавление канала проходит обязательную модерацию.
          p 3.4 При создании заявки на добавление канала, Представитель  должен написать https://t.me/Main_Energy и указать обязательную информацию:
          ul
            li — Ссылка на канал;
            li — Название канала;
            li — Тематика канала;
            li — Описание канала, аудитории, охвата.
            li 3.5 Канал не может быть добавлен в каталог если при модерации выявлены следующие нарушения:
          br
          p 3.5.1 Канал заблокирован или неактивный;
          p 3.5.2 В заявке указана неверная ссылка на канал;
          p 3.5.3 Представитель не является админом или владельцем заявленного канала;
          ul
            li 3.5.4 Канал не имеет цели принимать и размещать рекламные Проекты;
            li 3.5.5 Канал долгое время не публикует публичные сообщения;
            li 3.5.7 Аудитория канала и её поведение вызывает подозрение (накрутка подписчиков, просмотров и прочая неестественная активность)
          br
          p 3.6 Уже добавленный канал может быть исключен из каталога с течением времени, если упомянутые нарушения (п. 3.5) вновь выявлены.
          p 3.8 Если канал заподозрен в использовании «серых» и иных способов «накрутки» (не органический рост) подписчиков, аккаунт администратора подлежит полной блокировке. Все средства, оставшиеся на балансе такого аккаунта списываются в пользу рекламодателя и не подлежат выводу.
          p 3.9 Все детальные условия и специфику нашей работы вы можете изучить здесь
            a(href="https://t.me/SE_cooperation") https://t.me/SE_cooperation
          br
          p
            strong 4. Публикация Проекта
          p 4.1 Решение о публикации рекламного Проекта принимает менеджер по установленным ограничениям от Представителя канала.
          p 4.2 Представитель имеет право принять Проект в работу либо отклонить заявку(см. п.2.6.).
          p 4.3 При принятии Проекта, Представитель обязуется обеспечить рекламному посту 1 час в топе канала и 24 часа в ленте.
          p 4.4 После публикации рекламного поста средства за рекламное размещение переходят в “Холд” на аккаунт Представителя. С Холда на баланс деньги поступят после соблюдения условия размещения рекламы 1 час топа и 24 часа в ленте.
          p 4.5. При несоблюдении условий и пожеланий к размещению Рекламодатель имеет право отменить оплату проекта при проверке (см. п. 2.7) подав жалобу на рассмотрение администраторов Сервиса.
          br
          p
            strong 5. Оплата и Вывод средств с баланса.
          p 5.1 Пополнение и вывод средств с баланса может производить как Рекламодатель, так и Представитель.
          p 5.1.1 Рекламодатель может заказать вывод средств с удержанием 25% комиссии от суммы вывода нашим проектом!
          p 5.2 Доступные методы Вывода средств с баланса Выплаты производятся через наш сайт, в вашем аккаунте будет вкладка » Выплаты «. Денежные средства переводим только на Яндекс деньги и QIWI кошелек. Убедительная просьба не запрашивать выплату чаще, чем раз в 7 дней!
          p 5.3 Оформленная заявка на вывод обрабатывается в течении 24-х часов.
          p 5.4 Оплата Рекламодателями осуществляется только на те счета которые им предоставляют менеджера 
            a(href="https://t.me/Social_Energy") https://t.me/Social_Energy
            | .
          br
          p
            strong 6. Ответственность Администрации
          p 6.1 Сервис, его программное обеспечение и весь контент, содержащийся в Сервисе, предоставляются по принципу «как есть» и «как доступно». Администрация не предоставляет никаких прямых и косвенных гарантий, в частности о пригодности или удобстве использования Сервиса или любого контента, доступ к которому осуществляется через Сервис.
          p 6.2 Администрация прилагает все усилия для обеспечения бесперебойной работы Сервиса. Однако, Администрация не несёт ответственности за временную недоступность Сервиса из-за технических неисправностей, что является последствием независимых от Администрации обстоятельств.
          p 6.3 Администрация принимает меры для предотвращения появления “накрученных” каналов, а также недействительных или неправомерных предложений, однако НЕ гарантирует максимальную эффективность таких мер.
          p 6.4 Пользователь Сервиса (Заказчик и Владелец) самостоятельно несет все риски, а Сервис не несет ответственности за выбранные пользователем способы получения вознаграждения. Пользователь сервиса сам выбирает какие рекламные записи ему размещать и сам несет за них ответсвенность.
          p 6.5 Пользователь (Заказчик и Владелец) принимает и соглашается с тем, что он использует Сервис в собственных коммерческих целях, и на использование Сервиса не распространяет свое действие Закон РФ от 07.02.1992 № 2300-1 «О защите прав потребителей». Пользователь Сервиса не является «потребителем» в рамках указанного Закона, так как использует Сервис для получения коммерческой выгоды и (или) в целях продвижения собственного продукта, канала, бренда или сервиса.
          br
          p
            strong 7. Заключительные положения
          p 7.1 Администрация оставляет за собой право время от времени вносить изменения, добавлять и исключать положения Правил.
          p 7.2 Новая редакция Правил вступает в силу с момента её размещения на Сайте или в Приложении, если другое не указано в новой редакции Правил. 
          p 7.3 Представитель может прекратить действие Правил в любой момент путем прекращения использования Сервиса, и уведомления об этом Администрации.
          p 7.4 Администрация может прекратить действие Правил в любой момент, предварительно предупредив об этом Рекламодателя или Представителя путём направления сообщения на аккаунт telegram который указывают при регистрации. Прекращение действия общих Правил пользования Сервисом также означает прекращения действия настоящих Правил.
  catalog-footer  
</template>

<script>
// Components
import HomeNavigation from "@/components/Navigations/HomeNavigation";
import CatalogFooter from "@/components/CatalogFooter";

export default {
  name: "TermsOfUseView",
  components: { HomeNavigation, CatalogFooter },
};
</script>

<style scoped lang="scss">
main {
  background-color: #fafafa;
}

ul {
  padding-left: 46px;
}

h2 {
  font-size: 32px;
}

.card {
  background: #fff;
  border-radius: 15px !important;
  box-shadow: 0 0 8px 0 #ededed !important;
}
</style>
