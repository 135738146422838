<template lang="pug">
v-card.card
  .top.d-flex.pa-0.pr-4
    div
      a(href="#").avatar
        v-img(:src="channel.avatar")
    div.base-info.d-flex.flex-column.py-4
      div
        a(href="#").name 
          | Dlw full telega 18+
        div.base-info-text.d-flex.align-center
          v-icon mdi-account-group
          div 357 360 подписчиков
        div.base-info-text.d-flex.align-center
          v-icon mdi-eye
          div 39 300
      div
        div.category
          div.category-name.d-flex.align-center
            v-icon mdi-list-box
            div Категория
          div.category-text.category-text-purple Новости 18+
      div.d-flex.align-center
        div.category
          div.category-name.d-flex.align-center
            v-icon mdi-timer-sand-full
            div ТОП / Лента
          div.category-text 1/24
        div.category
          div.category-name.d-flex.align-center
            v-icon mdi-timer-sand-full
            div CPM
          div.category-text.category-text-blue 7300
  v-divider
  .middle.d-flex.align-center.pa-4
    div
      div.d-flex.align-center
        v-icon.middle-icon mdi-earth
        div.middle-text Страна
      div.middle-text Россия
    div
      div.d-flex.align-center
        v-icon.middle-icon mdi-timer-sand-full
        div.middle-text Принятие заявки
      div.middle-text До 1 часа
  .bottom.align-center.d-flex.pt-2
    div.d-flex.align-center.justify-center
      div 28 920
      v-icon(color="black") mdi-currency-rub
    div.bottom-btn.d-flex.align-center.justify-center
      v-icon mdi-cart
      div Добавить в корзину
</template>

<script>
export default {
  name: "CatalogChannelCardMobile",
  props: {
    channel: Object,
  },
};
</script>

<style scoped lang="scss">
.card {
  background: #fff;
  border-radius: 15px !important;
  box-shadow: 0 0 8px 0 #ededed !important;
  overflow: hidden;
}

.top {
  position: relative;

  & > * {
    flex: 1 1 auto;
  }
}

.middle {
  & > * {
    flex: 1 1;
  }

  &-icon {
    font-size: 14px !important;
    margin-right: 2px;
  }

  &-text {
    font-size: 14px;
    font-weight: 600;
  }
}

.name {
  color: var(--main-blue);
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.category {
  &-name {
    font-weight: 600;
    gap: 4px;

    & > * {
      font-size: 14px !important;
    }
  }

  &-text {
    font-weight: 500;
    font-size: 14px;

    &-purple {
      color: #8f3faf;
    }

    &-blue {
      color: var(--main-blue);
    }
  }
}

.base-info {
  gap: 12px;

  & > *:last-child > * {
    flex: 1 1 auto;
  }

  &-text {
    gap: 6px;

    & > * {
      color: #a8adb9 !important;
      font-size: 14px;
    }
  }
}

.bottom {
  & > * {
    flex: 1 1;

    &:first-child {
      & > * {
        font-size: 14px !important;
        font-weight: 500;
        color: #000000de !important;
      }
    }
  }

  &-btn {
    border-radius: 0;
    border-bottom-right-radius: 8px;
    padding: 0.8rem 0.8rem;
    background: #0ab534;
    gap: 4px;
    cursor: pointer;

    & > * {
      color: #fff !important;
      font-size: 14px !important;
    }
  }
}

.avatar {
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-height: 450px;
  width: 100%;
  padding: 20px;
  justify-content: center;

  & > * {
    height: 100%;
    width: 100%;
    border-radius: 15px !important;
  }
}

@media screen and (max-width: 600px) {
  .top {
    padding-right: 0 !important;
    flex-direction: column;
  }

  .base-info {
    padding: 16px !important;
    padding-top: 0 !important;
  }
}
</style>
