<template lang="pug">
v-dialog(v-model="openLocal" max-width=500)
  div.root
    v-card.card.pa-4
      div.top.d-flex.align-center.mb-11
        div.top-account.d-flex.flex-column.align-center
          div.top-account-icon
            v-icon mdi-account-circle
          h4.font-weight-bold АВТОРИЗАЦИЯ
        v-icon(
          style="cursor: pointer;"
          @click="closeSigninDialog"
        ).ml-auto mdi-close
      div(v-if="showError").warning-fields.pa-4.mb-4
        div(v-html="loginError")
        div(v-html="passwordError")
        div(v-html="formError")
      div.fields.pa-4.px-3
        v-form(
          v-model="formValid"
          @submit.prevent="signin"
        )
          v-text-field(
            prepend-inner-icon="mdi-account" 
            label="Логин:" 
            outlined 
            dense
            v-model="login"
            hide-details="auto"
          ).signin-dialog-text-field.mb-3
          v-text-field(
            prepend-inner-icon="mdi-lock" 
            label="Пароль:" 
            outlined 
            dense
            v-model="password"
            hide-details="auto"
          ).signin-dialog-text-field
      div.remember.d-flex.align-center.justify-space-between.mt-2
        label.d-flex.align-center.font-weight-medium
          input(type="checkbox" name="rememberMe" :checked="rememberMe").mr-1
          | Запомнить меня
        a(href="/forget-password").text-decoration-none Забыли пароль?
      div.buttons.d-flex.flex-column.mt-4
        div(@click="checkFieldsValid").btn-blue
          span Войти
        div.btn-telegram
          span Telegram
        div.btn-red
          span Google
</template>

<script>
import axios from "axios";

export default {
  name: "SigninDialog",
  props: {
    open: Boolean,
  },
  data() {
    return {
      formError: null,
      loginError: null,
      passwordError: null,
      emailRegex: /.+@.+\..+/,
      formValid: false,
      login: null,
      password: null,
      rememberMe: false,
    };
  },
  created() {
    // Получить куки на случай, если ранее checked rememberMe
    this.getCookieDataForRememberMe();
  },
  computed: {
    openLocal: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("changeSigninDialog", value);
      },
    },
    showError() {
      return this.loginError || this.passwordError;
    },
  },
  methods: {
    closeSigninDialog() {
      this.formError = null;
      this.loginError = null;
      this.passwordError = null;
      this.$emit("changeSigninDialog", false);
    },
    changeErrors(errorMessage, field) {
      switch (field) {
        case "login":
          this.loginError = errorMessage;
          break;
        case "password":
          this.passwordError = errorMessage;
          break;
        case "rememberMe":
          this.rememberMeError = errorMessage;
          break;
      }
    },
    checkValueSimillarMail(value) {
      const regex = /.{4}@/;

      return regex.test(value);
    },
    checkFieldsValid() {
      const loginRegex = /^[a-zA-Z0-9_]{4,}$/;
      const passwordRegex = /^[a-zA-Z0-9]{6,}/;

      if (this.login) {
        if (this.checkValueSimillarMail(this.login)) {
          this.emailRegex.test(this.login)
            ? this.changeErrors(null, "login")
            : this.changeErrors("Почта введена не корректно", "login");
        } else {
          loginRegex.test(this.login)
            ? this.changeErrors(null, "login")
            : this.changeErrors("Логин введен не корректно", "login");
        }
      } else {
        this.changeErrors("Введите логин или почту", "login");
      }

      if (this.password) {
        passwordRegex.test(this.password)
          ? this.changeErrors(null, "password")
          : this.changeErrors("Проверьте пароль", "password");
      } else {
        this.changeErrors("Введите пароль", "password");
      }

      !this.loginError && !this.passwordError ? this.signin() : null;
    },
    signin() {
      let userName = "";
      let email = "";

      this.emailRegex.test(this.login)
        ? (email = this.login)
        : (userName = this.login);

      axios
        .post(this.$store.state.api_url + "login", {
          userName: userName,
          email: email,
          password: this.password,
        })
        .then((response) => {
          console.log(response.data);

          // Сохранить куки для rememberMe
          this.rememberMe
            ? this.saveCookieForRememberMe()
            : this.deleteCookieForRememberMe();
        })
        .catch((error) => {
          this.formError = error;
          console.log(error);
        });
    },
    getCookieDataForRememberMe() {
      // Разделяем строку cookie на отдельные куки
      const cookies = document.cookie.split(";");

      // Проходим по каждой части строки cookie
      cookies.forEach((cookie) => {
        const [cookieName, cookieValue] = cookie
          .split("=")
          .map((c) => c.trim());

        // Проверяем имя куки и извлекаем значения login и password
        if (cookieName === "login") {
          this.login = cookieValue;
        } else if (cookieName === "password") {
          this.password = cookieValue;
        }
      });

      this.login && this.password ? (this.rememberMe = true) : null;
    },
    deleteCookieForRememberMe() {
      const path = this.$route.path;

      // Устанавливаем время жизни куки на прошедшую дату для их удаления
      document.cookie = `${encodeURIComponent("login")}=${encodeURIComponent(
        ""
      )}; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
      document.cookie = `${encodeURIComponent("password")}=${encodeURIComponent(
        ""
      )}; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
    },
    saveCookieForRememberMe() {
      const login = this.login;
      const password = this.password;

      const path = this.$route.path;

      let date = new Date(Date.now() + 86400e3);
      date = date.toUTCString();

      document.cookie = `${encodeURIComponent("login")}=${encodeURIComponent(
        login
      )}; path=${path}; expires=Tue; ${date}`;

      document.cookie = `${encodeURIComponent("password")}=${encodeURIComponent(
        password
      )}; path=${path}; expires=Tue; ${date}`;
    },
  },
};
</script>

<style scoped lang="scss">
.root {
  overflow: auto;
  padding-top: 20px;
  max-height: 90vh;
  border-radius: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  border-radius: 20px !important;
}

.remember {
  & > * {
    font-size: 14px;
    width: fit-content;
  }
}

.warning-fields {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
}

.buttons {
  gap: 12px;
}

.fields {
  border: 1px solid #e2e2e2;
  border-radius: 8px !important;
}

.signin-dialog-text-field {
  border-radius: 8px;
}

.top {
  position: relative;

  &-account {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%);

    &-icon {
      padding: 4px;
      background-color: #fff;
      border-radius: 50%;
      border-top: 1px solid #d8d8d8;

      & > * {
        font-size: 62px;
        color: black;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .warning-fields {
    font-size: 14px;
  }
}
</style>
