<template lang="pug">
v-card.card
  div.image
    img(:src="data.image" alt="post image")
  div.pa-4
    div.blog-title.mb-4 {{ data.title }}
    div.blog-description.mb-4 {{ data.small_description }}
    router-link(:to="{ name: 'post', params: { slug: data.link } }").btn-blue.btn-pd
      span Перейти
</template>

<script>
export default {
  name: "BlogSmallPost",
  props: {
    data: Object,
  },
};
</script>

<style scoped lang="scss">
.card {
  box-shadow: 0px 4px 54px rgba(166, 166, 166, 0.21) !important;
  border-radius: 20px !important;
  overflow: hidden;
  background: #fff;
}

.btn-pd {
  width: fit-content;
}

.blog-title {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 700;
}

.image {
  height: 244px;

  & > img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .image {
    height: 120px;
  }

  .blog-title {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .blog-description {
    font-size: 0.95rem;
  }
}
</style>
