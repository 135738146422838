<template lang="pug">
header
  v-container.container-pd.py-16
    div.main.d-flex.align-center.flex-wrap.pt-16
      div.left
        h1.header-title
          span.first-word-header Биржа
          br
          span.second-word-header Рекламы
          br
          span.third-word-header в телеграм каналах
        div.header-description
          | Мы отличаемся низкими комиссиями на пополнение, наличием тысяч качественно отобранных каналов различных тематик и превосходным функционалом. 
          br
          | Здесь вы сможете быстро приобрести рекламу в телеграме!
        div.btns.d-flex.flex-wrap.align-center
          a(href="/catalog").btn-blue.btn-pd
            span Выбрать каналы
          a(
            @click="openSignupDialog"
            href="javascript:void(0)"
          ).btn-green.btn-pd
            span Регистрация
      div.right.pl-4
        div.right-image
          v-img(src="@/assets/header-img.svg")
  signup-dialog(:open="signupDialog" @changeSignupDialog="setSignupDialog")
</template>

<script>
// Components
import SignupDialog from "@/components/dialogs/SignupDialog";

export default {
  name: "HomeHeader",
  components: { SignupDialog },
  data() {
    return {
      signupDialog: false,
    };
  },
  methods: {
    setSignupDialog(value) {
      this.signupDialog = value;
    },
    openSignupDialog() {
      this.signupDialog = !this.signupDialog;
    },
  },
};
</script>

<style scoped lang="scss">
.left {
  flex: 1;
  align-items: center;
}

.right {
  flex: 1;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);

  &-image {
    width: 100%;
  }
}

header {
  background: #f5f5f7;
}

.header-title {
  line-height: 1.1;
  letter-spacing: 1px;
}

.header-description {
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: black;
  font-weight: 500;
}
.first-word-header {
  font-size: 2.99rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2.5px;
}

.second-word-header {
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--main-blue);
  letter-spacing: 2.5px;
}

.third-word-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.103rem;
}

.btns {
  gap: 6px;
}

.btn-pd {
  padding: 0.8rem 2rem;
}

@media screen and (max-width: 1200px) {
  .third-word-header {
    font-size: 2.043rem;
  }
}

@media screen and (min-width: 1080px) {
  .right {
    flex: 0 0 auto;
    width: 58%;
  }
}

@media screen and (max-width: 1080px) {
  .right {
    display: none !important;
  }

  .header-title {
    text-align: center;
  }

  .btn-pd {
    width: 100%;
  }

  .btns {
    gap: 12px;
  }
}

@media screen and (max-width: 600px) {
  .first-word-header {
    font-size: 2.2rem;
  }

  .second-word-header {
    font-size: 3.8rem;
  }

  .third-word-header {
    font-size: 1.583rem;
  }
}
</style>
