<template lang="pug">
section(id="how-we-work").how-we-work
  v-container.container-pd.py-16
    h2.text-center Как работает наша биржа?
    div.box.d-flex.flex-column
      v-card(v-for="(card, index) in cards" :key="index").card.d-flex.align-center.justify-space-between
        div(style="max-width: 560px;")
          div.title-step {{ card.title }}
          a(
            href="javascript:void(0)"
            @click="signupDialog = !signupDialog" 
            v-if="card.link"
          ).btn-green.btn-pd
            span Регистрация
        div.custom-icon.ml-6
          v-icon {{ card.icon }}
      a(href="#").btn-blue.btn-pd.ma-auto
        span Заказать рекламу
  signup-dialog(:open="signupDialog" @changeSignupDialog="setSignupDialog")
</template>

<script>
// Components
import SignupDialog from "@/components/dialogs/SignupDialog";

export default {
  name: "HomeHowWeWork",
  components: { SignupDialog },
  methods: {
    setSignupDialog(value) {
      this.signupDialog = value;
    },
  },
  data() {
    return {
      signupDialog: false,
      cards: [
        {
          title: "Зарегистрируйтесь в нашей системе",
          icon: "mdi-account-plus",
          link: true,
        },
        {
          title: "Выберите каналы в зависимости от критериев вашего бизнеса",
          icon: "mdi-filter",
          link: false,
        },
        {
          title: "Пополнить баланс удобным для вас способом",
          icon: "mdi-cash",
          link: false,
        },
        {
          title:
            "Создать завлекающий пост с помощью уникального функционала Рекламного кабинета",
          icon: "mdi-cart-arrow-down",
          link: false,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 2.25rem;
  margin-bottom: 2.5rem;
  font-weight: 700;
}

.btn-pd {
  margin-top: 1rem !important;
  width: fit-content;
  padding: 0.7rem 3.5rem !important;
}

.box {
  background: #dae6ff !important;
  padding: 3rem !important;
  box-shadow: 0px 21px 72px rgba(170, 196, 246, 0.4);
  border-radius: 38px !important;
  gap: 24px;
}

.title-step {
  font-size: 1.125rem;
  font-weight: 700;
}

.card {
  background: #fff;
  box-shadow: 0px 4px 29px rgba(194, 194, 194, 0.25) !important;
  padding: 1rem !important;
  border-radius: 20px !important;

  & > *:first-child {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  & > *:last-child {
    margin-right: calc(var(--bs-gutter-x) * 0.5);
  }
}

.custom-icon {
  width: 78px;
  height: 78px;
  border-radius: 10px;
  padding: 36px;
  overflow: hidden;
  border: 2px solid var(--main-blue) !important;
  background-color: #c5d7fc;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    color: var(--main-blue) !important;
    font-size: 42px !important;
  }
}

@media screen and (max-width: 600px) {
  .box {
    padding: 2rem !important;

    & > *:last-child {
      margin-top: 0 !important;
    }
  }

  .image {
    margin: 0 !important;
  }

  .card {
    flex-direction: column-reverse;
    gap: 12px;
  }

  .title-step {
    text-align: center;
  }

  .btn-pd {
    margin: auto;
  }
}
</style>
