import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api_url: "http://localhost:5005/api/",
    user: null,
    channels: [],
  },
  getters: {},
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setChannels(state, payload) {
      state.channels = payload;
    },
  },
  actions: {},
  modules: {},
});
