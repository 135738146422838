import store from "../store";
import router from "../router";

export default {
  methods: {
    exitSystem(reload) {
      localStorage.clear();
      store.commit("setUser", null);
      reload ? location.reload() : router.push("/");
    },
  },
};
