<template lang="pug">
div
  home-splash
  home-navigation
  home-header
  main
    home-about
    home-how-we-work
    home-bots
    home-about-us
    home-categories
    home-channels-for-ad
  home-footer
</template>

<script>
import updateTitle from "@/mixins/updateTitle";

// Components
import HomeSplash from "@/components/splashScreens/HomeSplash";
import HomeNavigation from "@/components/Navigations/HomeNavigation";
import HomeHeader from "@/components/HomeHeader";
import HomeAbout from "@/components/HomeAbout";
import HomeHowWeWork from "@/components/HomeHowWeWork";
import HomeAboutUs from "@/components/HomeAboutUs";
import HomeCategories from "@/components/HomeCategories";
import HomeChannelsForAd from "@/components/HomeChannelsForAd";
import HomeFooter from "@/components/HomeFooter";
import HomeBots from "@/components/HomeBots";

export default {
  name: "HomeView",
  components: {
    HomeSplash,
    HomeNavigation,
    HomeHeader,
    HomeAbout,
    HomeHowWeWork,
    HomeAboutUs,
    HomeCategories,
    HomeChannelsForAd,
    HomeFooter,
    HomeBots,
  },
  mixins: [updateTitle],
  created() {
    this.updateTitle("GrandBot");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
