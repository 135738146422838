<template lang="pug">
footer
  v-container.custom-container.d-flex.align-center.justify-space-between.flex-wrap.py-6
    div.d-flex.align-center.flex-wrap.links
      a(href="/terms-of-use").text-decoration-none Пользовательское соглашение
      a(href="/privacy-policy").text-decoration-none Политика конфиденциальности
    div © GrandBot 2023
</template>

<script>
export default {
  name: "CatalogFooter",
};
</script>

<style scoped lang="scss">
footer {
  border-top: 1px solid #d8d8d8;
}

.custom-container {
  gap: 12px;
}

.links {
  gap: 24px;

  & > * {
    color: #2b393f;
    transition: 0.3s;

    &:hover {
      color: #9a9a9a;
      transition: 0.3s;
    }
  }
}
</style>
