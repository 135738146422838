<template lang="pug">
div
  home-navigation
  main.pt-16
    section(id="term").terms.py-16
      v-container.d-flex.justify-space-between.flex-column
        v-card.card.pa-5
          h2.text-center.mb-6 Политика конфиденциальности
          h2.subtitle-h2 Какие персональные данные мы собираем и с какой целью
          p Пользователь дает свое полное согласие на передачу, получение, хранение и использование Администрацией Системы SEAds любого вида информации и персональных данных, предназначенных для сотрудничества.
          p Администрация Системы SEAds, со своей стороны, гарантирует нераспространение полученных данных Пользователя.
          p Под защитой персональных данных Пользователя понимается комплекс мер (организационно-распорядительных, технических, юридических), направленных на предотвращение неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения персональных данных субъектов, а также от иных неправомерных действий.
          p Администрация сайта seads.me при защите персональных данных Пользователей принимает все необходимые организационно-распорядительные, юридические и технические меры, в том числе:
          ul
            li Шифровальные (криптографические) средства.
            li Антивирусная защита.
            li Обнаружение и предотвращение вторжений.
            li Управления доступом.
            li Регистрация и учет.
            li Обеспечение целостности.
          br
          p Доступ к персональным данным Пользователя имеют сотрудники Администрации сайта, которым персональные данные необходимы для всестороннего и полного выполнения своих обязанностей.
          p Под конфиденциальной информацией понимается любая информация, которая имеет действительную или потенциальную коммерческую ценность в силу неизвестности ее третьим лицам, к которой нет свободного доступа на законном основании и к сохранению конфиденциальности которой обладатель принимает все возможные меры.
          p Все объекты, размещенные на Сайте seads.me, или же переданы наши менеджерам, в том числе элементы дизайна, текст, графические изображения, иллюстрации, скрипты, программы, и другие объекты и их подборки (далее — Контент), являются объектами исключительных прав Администрации, Пользователей Сайта и других правообладателей, все права на эти объекты защищены.
          p Ничто в Пользовательском Соглашении SEAds не может быть рассмотрено как передача исключительных прав на Контент.
          p Передавая  свой Контент менеджерам, Пользователь передает Администрации право делать копии своего Контента с целью упорядочения и облегчения публикации и хранения пользовательского Контента на Сайте.
          h2.subtitle-h2 Куки
          p Если вы оставляете комментарий на нашем сайте, вы можете включить сохранение вашего имени, адреса email и вебсайта в куки. Это делается для вашего удобства, чтобы не заполнять данные снова при повторном комментировании. Эти куки хранятся в течение одного года.
          p Если у вас есть учетная запись на сайте и вы войдете в неё, мы установим временный куки для определения поддержки куки вашим браузером, куки не содержит никакой личной информации и удаляется при закрытии вашего браузера.
          p При входе в учетную запись мы также устанавливаем несколько куки с данными входа и настройками экрана. Куки входа хранятся в течение двух дней, куки с настройками экрана — год. Если вы выберете возможность «Запомнить меня», данные о входе будут сохраняться в течение двух недель. При выходе из учетной записи куки входа будут удалены.
          p При редактировании или публикации статьи в браузере будет сохранен дополнительный куки, он не содержит персональных данных и содержит только ID записи отредактированной вами, истекает через 1 день.
          h2 Встраиваемое содержимое других вебсайтов
          p Статьи на этом сайте могут включать встраиваемое содержимое (например видео, изображения, статьи и др.), подобное содержимое ведет себя так же, как если бы посетитель зашел на другой сайт.
          p Эти сайты могут собирать данные о вас, использовать куки, внедрять дополнительное отслеживание третьей стороной и следить за вашим взаимодействием с внедренным содержимым, включая отслеживание взаимодействия, если у вас есть учетная запись и вы авторизовались на том сайте.
          h2 Как долго мы храним ваши данные
          p Если вы оставляете комментарий, то сам комментарий и его метаданные сохраняются неопределенно долго. Это делается для того, чтобы определять и одобрять последующие комментарии автоматически, вместо помещения их в очередь на одобрение.
          p Для пользователей с регистрацией на нашем сайте мы храним ту личную информацию, которую они указывают в своем профиле. Все пользователи могут видеть, редактировать или удалить свою информацию из профиля в любое время (кроме имени пользователя). Администрация вебсайта также может видеть и изменять эту информацию.
          h2 Какие у вас права на ваши данные
          p При наличии учетной записи на сайте или если вы оставляли комментарии, то вы можете запросить файл экспорта персональных данных, которые мы сохранили о вас, включая предоставленные вами данные. Вы также можете запросить удаление этих данных, это не включает данные, которые мы обязаны хранить в административных целях, по закону или целях безопасности.
  catalog-footer
</template>

<script>
// Components
import HomeNavigation from "@/components/Navigations/HomeNavigation";
import CatalogFooter from "@/components/CatalogFooter";

export default {
  name: "PrivacyPolicyView",
  components: { HomeNavigation, CatalogFooter },
};
</script>

<style scoped lang="scss">
main {
  background-color: #fafafa;
}

.subtitle-h2 {
  font-weight: 500;
}

ul {
  padding-left: 46px;
}

h2 {
  font-size: 32px;
}

.card {
  background: #fff;
  border-radius: 15px !important;
  box-shadow: 0 0 8px 0 #ededed !important;
}
</style>
