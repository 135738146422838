<template lang="pug">
div(v-if="channel !== null")
  home-splash
  catalog-navigation
  main
    section.channel
      div.custom-container.d-flex.flex-column.py-10.pb-16.px-6
        h5.ma-0 ИНФОРМАЦИЯ О КАНАЛЕ {{ channel.name }}
        div.wrapper.d-flex.align-start.pt-12
          div
            channel-card(:channel="channel")
          div.d-flex.flex-wrap.align-center
            // Карточки статистики канала
            channel-statistics-card(
              :data="channel.subscribes"
            )
            channel-statistics-card(
              :data="channel.index_citation"
            )
            channel-statistics-card(
              :data="channel.middle_coverage"
            )
            channel-statistics-card(
              :data="channel.middle_ad_coverage"
            )
            channel-statistics-card(
              :data="channel.day_coverage"
            )
            channel-statistics-card(
              :data="channel.orders_completed"
            )
    section.custom-container.recommended-channels.py-10.px-6
      h2.ma-0 Рекомендуемые каналы
      div.swiper.mt-6
        div.swiper-wrapper
          div.swiper-slide(
            v-for="(recommended_channel, index) in recommended_channels"
            :key="index"
          )
            div.py-6.px-4
              v-card.swiper-card
                div.swiper-card-top.d-flex.align-center.justify-center
                  div.swiper-card-top-avatar
                    v-img(:src="recommended_channel.avatar")
                div.swiper-card-middle
                  div.text-center.font-weight-medium {{ recommended_channel.name }}
                  div.text-center {{ recommended_channel.subscribes }} подписчиков
                div.swiper-card-bottom.align-center.d-flex.pt-6
                  div.d-flex.align-center.justify-center
                    div {{ recommended_channel.price }}
                    v-icon(color="black") mdi-currency-rub
                  router-link(
                    :to="{ name: 'channel', params: {link: recommended_channel.link} }"
                  ).text-decoration-none.d-flex.align-center.justify-center
                    v-icon mdi-dots-horizontal-circle
                    div Подробнее
    footer.d-flex.align-center.justify-space-between.flex-wrap.pa-6
      div.d-flex.align-center.flex-wrap
        router-link(to="/terms-of-use") Пользовательское соглашение
        router-link(to="/privacy-policy") Политика конфиденциальности
      div © Social Energy Group 2023
  catalog-button
</template>

<script>
import { Swiper } from "swiper";

// Components
import HomeSplash from "@/components/splashScreens/HomeSplash";
import CatalogNavigation from "@/components/Navigations/CatalogNavigation";
import CatalogButton from "@/components/CatalogButton";
import ChannelCard from "@/components/ChannelCard";
import ChannelStatisticsCard from "@/components/ChannelStatisticsCard";

export default {
  name: "ChannelView",
  components: {
    HomeSplash,
    CatalogNavigation,
    CatalogButton,
    ChannelCard,
    ChannelStatisticsCard,
  },
  data() {
    return {
      channel: null,
      channelSwiper: null,
      recommended_channels: [
        {
          name: "Смешно и точка",
          avatar:
            "https://seads.me/storage/channels/-1001497630296.jpg?nc=1691366706",
          price: "28 920",
          subscribes: "44 533",
          link: "vHVyCUZxhPwxY2Qy",
        },
        {
          name: "VedeoHub",
          avatar:
            "https://seads.me/storage/channels/-1001341680169.jpg?nc=1676760878",
          price: "11 000",
          subscribes: "486 605",
          link: "vHVyCUZxhPwxY2Qy",
        },
        {
          name: "ХИЩНИК",
          avatar:
            "https://seads.me/storage/channels/-1001244303663.jpg?nc=1678320034",
          price: "396",
          subscribes: "14 956",
          link: "vHVyCUZxhPwxY2Qy",
        },
        {
          name: "Папка исходников",
          avatar:
            "https://seads.me/storage/channels/-1001943499219.jpg?nc=1692748839",
          price: "170",
          subscribes: "10 963",
          link: "vHVyCUZxhPwxY2Qy",
        },
      ],
    };
  },
  created() {
    // Загулшка
    if (this.$route.params.link === "vHVyCUZxhPwxY2Qy") {
      this.channel = {
        name: "Dlw full telega 18+",
        avatar:
          "https://seads.me/storage/channels/-1001497630296.jpg?nc=1691366706",
        private_status: true,
        orders_completed: {
          more: [["0", "сегодня"]],
          design: {
            color: [],
          },
          title: "ВЫПОЛНЕНО ЗАЯВОК",
          name: "orders_completed",
          amount: "1",
          series: null,
        },
        day_coverage: {
          more: null,
          design: {
            color: "#be2ed6",
          },
          title: "ДНЕВНОЙ ОХВАТ",
          name: "day_coverage",
          amount: "947 224",
          series: [
            {
              name: "Охват",
              data: [
                {
                  x: "20 сентября",
                  y: 1920,
                },
                {
                  x: "21 сентября",
                  y: 1922,
                },
                {
                  x: "22 сентября",
                  y: 1922,
                },
                {
                  x: "23 сентября",
                  y: 1950,
                },
                {
                  x: "24 сентября",
                  y: 1980,
                },
                {
                  x: "25 сентября",
                  y: 1970,
                },
                {
                  x: "26 сентября",
                  y: 1972,
                },
              ],
            },
          ],
        },
        middle_ad_coverage: {
          more: [
            ["32 152", "за 12 часов"],
            ["43 406", "за 24 часа"],
            ["51 391", "за 48 часов"],
          ],
          design: {
            color: [],
          },
          title: "СРЕДНИЙ РЕКЛАМНЫЙ ОХВАТ 1 ПУБЛИКАЦИИ",
          name: "middle_ad_coverage",
          amount: "43 406",
          series: null,
        },
        middle_coverage: {
          more: [["25%", "ERR"]],
          design: {
            color: "#e6a6c6",
          },
          title: "СРЕДНИЙ ОХВАТ 1 ПУБЛИКАЦИИ",
          name: "middle_coverage",
          amount: "81 823",
          series: [
            {
              name: "Охват",
              data: [
                {
                  x: "20 сентября",
                  y: 1920,
                },
                {
                  x: "21 сентября",
                  y: 1922,
                },
                {
                  x: "22 сентября",
                  y: 1922,
                },
                {
                  x: "23 сентября",
                  y: 1950,
                },
                {
                  x: "24 сентября",
                  y: 1980,
                },
                {
                  x: "25 сентября",
                  y: 1970,
                },
                {
                  x: "26 сентября",
                  y: 1972,
                },
              ],
            },
          ],
        },
        index_citation: {
          more: [
            ["1 444", "уп. каналов"],
            ["6 147", "упоминаний"],
            ["425", "репостов"],
          ],
          design: {
            color: "#ffa500",
          },
          title: "ИНДЕКС ЦИТИРОВАНИЯ",
          name: "index_citation",
          amount: "1962.69",
          series: [
            {
              name: "Индекс",
              data: [
                {
                  x: "20 сентября",
                  y: 1920,
                },
                {
                  x: "21 сентября",
                  y: 1922,
                },
                {
                  x: "22 сентября",
                  y: 1922,
                },
                {
                  x: "23 сентября",
                  y: 1950,
                },
                {
                  x: "24 сентября",
                  y: 1980,
                },
                {
                  x: "25 сентября",
                  y: 1970,
                },
                {
                  x: "26 сентября",
                  y: 1972,
                },
              ],
            },
          ],
        },
        subscribes: {
          more: [
            ["-382", "вчера"],
            ["-5", "за неделю"],
            ["-4 019", "за месяц"],
          ],
          design: {
            color: "#1d64f2",
          },
          title: "ПОДПИСЧИКИ",
          name: "subscribes",
          amount: "319 864",
          series: [
            {
              name: "Подписчиков",
              data: [
                {
                  x: "20 сентября",
                  y: 1920,
                },
                {
                  x: "21 сентября",
                  y: 1922,
                },
                {
                  x: "22 сентября",
                  y: 1922,
                },
                {
                  x: "23 сентября",
                  y: 1950,
                },
                {
                  x: "24 сентября",
                  y: 1980,
                },
                {
                  x: "25 сентября",
                  y: 1970,
                },
                {
                  x: "26 сентября",
                  y: 1972,
                },
              ],
            },
          ],
        },
      };
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    this.channelSwiper = new Swiper(".swiper", {
      slidesPerView: 4,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        900: {
          slidesPerView: 2,
        },
        1080: {
          slidesPerView: 4,
        },
      },
    });
  },
};
</script>

<style scoped lang="scss">
footer {
  border-top: 1px solid #d8d8d8;
  gap: 20px;

  & > * {
    &:first-child {
      gap: 16px;

      & > * {
        font-size: 14px;
        text-decoration: none;
        color: #2b393f;
        transition: all 0.2s linear;

        &:hover {
          color: #9a9a9a;
          transition: all 0.2s linear;
        }
      }
    }
  }
}

.channel {
  background-color: #fafafa;
}

.swiper-card {
  background: #fff;
  border-radius: 15px !important;
  box-shadow: 0 0 8px 0 #ededed !important;

  &-bottom {
    & > * {
      flex: 1 1;

      &:first-child {
        & > * {
          font-size: 14px !important;
          font-weight: 500;
          color: #000000de !important;
        }
      }

      &:last-child {
        border-radius: 0;
        border-bottom-right-radius: 8px;
        padding: 0.8rem 0.8rem;
        background: #0ab534;
        gap: 4px;
        cursor: pointer;

        & > * {
          color: #fff !important;
          font-size: 14px !important;
        }
      }
    }
  }

  &-middle {
    & > * {
      &:first-child {
        font-size: 20px;
      }

      &:last-child {
        font-size: 14px;
      }
    }
  }

  &-top {
    position: relative;

    &-avatar {
      height: 152px;
      width: 152px;
      position: relative;
      top: -20px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      & > * {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.recommended-channels {
  background-color: #fff;
}

h5 {
  font-size: 1.25rem;
  font-weight: 700;
}

.wrapper {
  gap: 24px;

  & > * {
    &:first-child {
      flex: 1 1 35%;
    }

    &:last-child {
      flex: 1 1 65%;
      gap: 30px;
    }
  }
}

@media screen and (max-width: 1080px) {
  .wrapper {
    flex-direction: column;

    & > * {
      width: 100%;

      &:last-child {
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .custom-container {
    padding: 40px 8px !important;
  }
}
</style>
