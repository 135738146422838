<template lang="pug">
a(href="#").button.pulse
  v-icon.icon mdi-shopping
</template>

<script>
export default {
  name: "CatalogButton",
};
</script>

<style scoped lang="scss">
.button {
  padding: 20px;
  border-radius: 100%;
  background-color: #28a745;
  position: fixed;
  bottom: 35px;
  right: 35px;
  z-index: 100;
}

.icon {
  font-size: 24px !important;
  color: #fff !important;
}

.pulse {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  animation: radial-pulse 1s infinite;
}

@keyframes radial-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 136, 56, 0.51);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(33, 136, 56, 0);
  }
}
</style>
