<template lang="pug">
div
  div.d-flex.justy-space-between.align-center.main
    div.d-flex.align-center
      div(style="gap: 12px;").d-flex.align-end
        div
          div.item-lable.mb-2 Сортировать по
          v-select(
            solo
            prepend-inner-icon="mdi-refresh"
            v-model="sort"
            :item="sorts"
            item-text="name"
            hide-details
          ).catalog-field
    div.d-flex.align-center
      div(style="gap: 12px;").d-flex.align-end
        div
          div.item-lable.mb-2 Канал
          v-text-field(
            solo
            placeholder="Введите канал"
            prepend-inner-icon="mdi-book-edit"
            hide-details
            v-model="channel"
          ).catalog-field
  div.mt-4
    div.item-lable.mb-2 Страна
    v-select(
      solo
      v-model="country"
      :items="countries"
      prepend-inner-icon="mdi-earth"
      hide-details
    ).catalog-field
</template>

<script>
export default {
  name: "CatalogFilterTop",
  data() {
    return {
      channel: null,
      sort: null,
      sorts: [
        {
          name: "Новизне",
        },
        {
          name: "Популярности",
        },
        {
          name: "Охвату",
        },
      ],
      country: "Россия",
      countries: ["Россия", "Узбекситан", "Беларуссия", "Казахстан"],
    };
  },
};
</script>

<style scoped lang="scss">
.item-lable {
  font-weight: 700;
  font-size: 14px;
}

.main {
  gap: 24px;
}
</style>
