<template lang="pug">
v-card.card.d-flex.align-center.pa-4
  div.text-center.unique
    a(href="javascript:void(0);").d-inline-block
      div.d-flex.align-center.justify-center
        font-awesome-icon(icon="fa-solid fa-sort").mr-2
        div Канал
  div.text-center.unique
    a(href="javascript:void(0);").d-inline-block
      div.d-flex.align-center.justify-center
        font-awesome-icon(icon="fa-solid fa-sort").mr-2
        div Цена
  div.text-center.unique
    a(href="javascript:void(0);").d-inline-block
      div.d-flex.align-center.justify-center
        font-awesome-icon(icon="fa-solid fa-sort").mr-2
        div CPM
  div.text-center Категория
  div.text-center ТОП / Лента
  div.text-center В корзину
</template>

<script>
export default {
  name: "CatalogChannelFilter",
};
</script>

<style scoped lang="scss">
.card {
  background: #fff;
  border-radius: 15px !important;
  box-shadow: 0 0 8px 0 #ededed !important;

  & > * {
    flex: 1 1 auto;
    font-weight: 600;

    &:first-child {
      flex: 2;
    }
  }
}

.unique {
  position: relative;
  top: 1px;

  & > a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1080px) {
  .card {
    & > * {
      &:nth-child(4) {
        display: none;
      }

      &:nth-child(5) {
        display: none;
      }

      &:nth-child(6) {
        display: none;
      }
    }
  }
}
</style>
