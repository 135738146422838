import { render, staticRenderFns } from "./HomeChannelsForAd.vue?vue&type=template&id=b53a7268&scoped=true&lang=pug&"
import script from "./HomeChannelsForAd.vue?vue&type=script&lang=js&"
export * from "./HomeChannelsForAd.vue?vue&type=script&lang=js&"
import style0 from "./HomeChannelsForAd.vue?vue&type=style&index=0&id=b53a7268&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b53a7268",
  null
  
)

export default component.exports