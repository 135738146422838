<template lang="pug">
v-card(elevation="0")
  div.main
    div.image-features
      img(:src="data.image" :alt="data.image_alt")
    div.title-features {{ data.title }}
    div.font-weight-medium {{ data.subtitle }}
</template>

<script>
export default {
  name: "HomeAboutCard",
  props: {
    data: Object,
  },
};
</script>

<style scoped lang="scss">
.main {
  background: #fff;
  box-shadow: 0px 4px 54px rgba(166, 166, 166, 0.21);
  border-radius: 20px !important;
  padding: 2rem 2.7rem;
  margin-bottom: 30px;
}

.image-features {
  height: 60px;
  margin-bottom: 25px;

  & > * {
    width: 60px;
    height: 100%;
  }
}

.title-features {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 15px;
}
</style>
