<template lang="pug">
v-card(
  elevation="0" 
  @click="$router.push({ name: 'post', params: { slug: data.link } })"
).card.d-flex.flex-column.pa-0
  div.image
    img(:src="data.image" alt="post image")
  div.post-title.pa-4 {{ data.title }}
</template>

<script>
export default {
  name: "BlogSidebarRecommendedPost",
  props: {
    data: Object,
  },
};
</script>

<style scoped lang="scss">
.card {
  overflow: hidden;
}

.image {
  height: 100px;
  width: 100%;

  & > img {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 1080px) {
  .image {
    height: 180px;
  }
}

@media screen and (max-width: 600px) {
  .image {
    height: 120px;
  }
}
</style>
