<template lang="pug">
nav
  div.container-pd.d-flex.align-center.justify-space-between.pa-6
    div(style="gap: 24px;").left.d-flex.align-center
      a(href="/").logo
        img(src="@/assets/grandbot-logo.png")
      div.switch
        switch-language
    div.right
      v-btn(@click="openSigninDialog").btn-pd.btn-pd-blue.text-none
        v-icon(left) mdi-account
        | Войти
      v-btn(@click="openSignupDialog").btn-pd.btn-pd-green.text-none
        v-icon(left) mdi-account
        | Регистрация
    div.mobile
      div(@click="menu = !menu" :class="menu ? 'menu-active' : null").menu-icon
        span
        span
        span
  home-drawer(:menu="menu" :links="links" @changeMenu="setMenu" @changeSigninDialog="setSigninDialog")
  signin-dialog(:open="signinDialog" @changeSigninDialog="setSigninDialog")
  signup-dialog(:open="signupDialog" @changeSignupDialog="setSignupDialog")
</template>

<script>
// Components
import SigninDialog from "@/components/dialogs/SigninDialog";
import SignupDialog from "../dialogs/SignupDialog";
import SwitchLanguage from "@/components/single/SwitchLanguage";
import HomeDrawer from "@/components/drawers/HomeDrawer";

export default {
  name: "CatalogNavigation",
  data() {
    return {
      signinDialog: false,
      signupDialog: false,
      menu: false,
      links: [
        {
          section: true,
          href: "#about",
          text: "О нас",
        },
        {
          section: false,
          href: "#",
          text: "Пользовательское соглашение",
        },
        {
          section: true,
          href: "#",
          text: "Политика конфиденциальности",
        },
      ],
    };
  },
  components: { HomeDrawer, SigninDialog, SignupDialog, SwitchLanguage },
  methods: {
    setMenu(value) {
      this.menu = value;
    },
    setSigninDialog(value) {
      this.signinDialog = value;
    },
    openSigninDialog() {
      this.signinDialog = !this.signinDialog;
    },
    setSignupDialog(value) {
      this.signupDialog = value;
    },
    openSignupDialog() {
      this.signupDialog = !this.signupDialog;
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  border-bottom: 1px solid #d8d8d8;
  background-color: #fff;
}

.container-pd {
  height: 86px;
}

.logo {
  max-width: 200px;
  height: 140px;
  position: relative;
  left: -24px;

  & > * {
    height: 100%;
    width: 100%;
  }
}

.mobile {
  display: none;
}

.btn-pd {
  border-radius: 4px !important;
  box-shadow: none !important;
  background-color: #fff !important;

  &-green {
    border: 1px solid var(--main-green) !important;
    color: var(--main-green) !important;
  }

  &-blue {
    border: 1px solid var(--main-blue) !important;
    color: var(--main-blue) !important;
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 24px;

  & > * {
    width: fit-content;
  }
}

.menu {
  &-active {
    & > *:nth-child(2)::before {
      left: 20%;
    }

    & > *:last-child::before {
      left: 40%;
    }
  }

  &-icon {
    display: none;
    position: relative;
    width: 56px;
    height: 46px;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;

    & > * {
      height: 10px;
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 5px;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.2s linear;
        border-radius: 5px;
        background-color: var(--main-blue);
        box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34);
      }
    }

    &:hover {
      & > *:nth-child(2)::before {
        left: 20%;
      }

      & > *:last-child::before {
        left: 40%;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .switch {
    display: none;
  }

  .right {
    display: none;
  }

  .menu {
    &-icon {
      display: flex;
    }
  }

  .mobile {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .menu {
    &-icon {
      width: 46px;
      height: 36px;

      & > * {
        height: 8px;
      }
    }
  }
}
</style>
