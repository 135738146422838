<template lang="pug">
v-card.card.d-flex.flex-column
  div.top.d-flex.align-center
    div.avatar
      img(:src="data.avatar" alt="avatar")
    div
      a(:href="data.link").name {{ data.name }}
      div(style="font-size: 14px;").font-weight-medium Подписчиков: {{ data.subscribes }}
  div.middle.d-flex.flex-column
    div.item
      v-icon mdi-account-eye
      |  Просмотры: {{ data.views }}
    div.item
      v-icon mdi-view-comfy
      span(v-for="(category, index) in data.categories" :key="index")  {{ category.name }}
    div.item
      v-icon mdi-clock-time-eight
      |  ТОП/Лента: {{ data.timing }}
    div.item
      | CPM: {{ data.cpm }}
    a(:href="data.link").btn-blue
      span.d-flex.align-center
        v-icon.mr-1.white--text mdi-chart-bar-stacked
        | Статистика
  div.bottom.d-flex.align-center
    div.bottom-price {{ data.price }}{{ data.currency }}
    a(:href="data.link").bottom-btn Купить
</template>

<script>
export default {
  name: "HomeChannelsForAdCard",
  props: {
    data: Object,
  },
};
</script>

<style scoped lang="scss">
.card {
  border-radius: 15px !important;
  background: #fff;
  padding: 1rem 1rem 0 1rem;
  overflow: hidden;
  gap: 12px;
}

.item {
  background: #f5f5f7;
  border: 2px solid #dedede;
  padding: 0.5rem;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 4px 54px rgba(166, 166, 166, 0.21);
}

.middle {
  gap: 12px;
}

.top {
  gap: 16px;
}

.avatar {
  border-radius: 50px;
  max-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }
}

.bottom {
  & > * {
    flex: 1 1;
  }

  &-price {
    text-align: center;
    display: block;
    color: var(--green-dark);
    padding: 0.5rem;
  }

  &-btn {
    display: block;
    background: var(--green-dark);
    padding: 0.8rem;
    border-bottom-right-radius: 14px;
    border-top-left-radius: 15px;
    position: relative;
    right: -16px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    transition: 0.2s;
  }
}

.name {
  text-decoration: none;
  color: var(--main-blue);
  transition: 0.2s;

  &:hover {
    color: #031438;
    transition: 0.2s;
  }
}
</style>
