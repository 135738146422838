<template lang="pug">
v-dialog(v-model="openLocal" max-width=500)
  div.root
    v-card.card.pa-4
      div.top.d-flex.align-center.mb-11
        div.top-account.d-flex.flex-column.align-center
          div.top-account-icon
            v-icon mdi-account-circle
          h4.font-weight-bold Регистрация
        v-icon(
          style="cursor: pointer;"
          @click="closeSignupDialog"
        ).ml-auto mdi-close
      div(
        v-if="showError"
      ).warning-fields.pa-4.mb-4
        div(v-html="formError")
        div(v-html="loginError")
        div(v-html="passwordError")
        div(v-html="first_nameError")
        div(v-html="last_nameError")
        div(v-html="agreeError")
      div.fields.pa-4.px-3
        v-form(
          v-model="formValid"
          @submit.prevent="signup"
        )
          v-window(v-model="window").pt-2
            v-window-item(:value="1")
              v-text-field(
                prepend-inner-icon="mdi-account" 
                label="Логин:"
                v-model="login" 
                outlined 
                dense
                hide-details="auto"
              ).signin-dialog-text-field
              div.devset.mb-2 Логин должен содержать минимум 4 символа
              v-text-field(
                prepend-inner-icon="mdi-account" 
                label="Пароль:"
                v-model="password" 
                outlined 
                dense
                hide-details="auto"
              ).signin-dialog-text-field
              div.devset Пароль должен содержать:
                ul.pl-6
                  li Одну заглавную букву
                  li Минимум 8 символов
            v-window-item(:value="2")
              v-text-field(
                prepend-inner-icon="mdi-account" 
                label="Имя:"
                v-model="first_name" 
                outlined 
                dense
                hide-details="auto"
              ).mb-4
              v-text-field(
                prepend-inner-icon="mdi-account" 
                label="Фамилия:"
                v-model="last_name" 
                outlined 
                dense
                hide-details="auto"
              )
            v-window-item(:value="3")
              v-text-field(
                prepend-inner-icon="mdi-account" 
                label="Почта:"
                v-model="email" 
                outlined 
                dense
                hide-details="auto"
              ).signin-dialog-text-field.mb-2
          label(
            v-if="window === 3"
          ).d-flex.align-start.font-weight-medium
            input( 
              type="checkbox" 
              name="agree" 
              :checked="agree"
              v-model="agree"
            ).agree-input
            div.agree
              | Я соглашаюсь с 
              a(href="#") пользовательским соглашением 
              | и 
              a(href="#") политикой конфиденциальности
      div.buttons.d-flex.flex-column.mt-4
        div(
          v-if="window === 3"
          @click="checkFieldsValidAndNextWindow"
        ).btn-blue
          span Регистрация
        div(
          v-else
          @click="checkFieldsValidAndNextWindow"
        ).btn-blue
          span Продолжить
        div.btn-telegram
          span Через Telegram
        div.btn-red
          span Через Google
</template>

<script>
import axios from "axios";

export default {
  name: "SignupDialog",
  props: {
    open: Boolean,
  },
  data() {
    return {
      formValid: false,
      formError: null,
      window: 1,
      agree: false,
      agreeError: false,
      loginError: null,
      passwordError: null,
      first_nameError: null,
      last_nameError: null,
      emailError: null,
      login: null,
      password: null,
      first_name: null,
      last_name: null,
      email: null,
    };
  },
  computed: {
    openLocal: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("changeSignupDialog", value);
      },
    },
    showError() {
      return (
        this.formError ||
        this.loginError ||
        this.passwordError ||
        this.first_nameError ||
        this.last_nameError ||
        this.emailError ||
        this.agreeError
      );
    },
  },
  methods: {
    closeSignupDialog() {
      this.formError = null;
      this.loginError = null;
      this.passwordError = null;
      this.first_nameError = null;
      this.last_nameError = null;
      this.emailError = null;
      this.agreeError = null;
      this.$emit("changeSignupDialog", false);
    },
    checkFieldsValidAndNextWindow() {
      const loginRegex = /^[a-zA-Z0-9_]{4,}$/;
      const passwordRegex = /^(?=.*[A-Z]).{8,}$/;
      const emailRegex = /.+@.+\..+/;

      if (this.window === 1) {
        this.login && loginRegex.test(this.login)
          ? this.changeErrors(null, "login")
          : this.changeErrors("Проверьте логин", "login");

        this.password && passwordRegex.test(this.password)
          ? this.changeErrors(null, "password")
          : this.changeErrors("Проверьте пароль", "password");

        !this.loginError && !this.passwordError ? (this.window = 2) : null;
      } else if (this.window === 2) {
        this.first_name
          ? this.changeErrors(null, "first_name")
          : this.changeErrors("Введите имя", "first_name");

        this.last_name
          ? this.changeErrors(null, "last_name")
          : this.changeErrors("Введите фамилию", "last_name");

        !this.first_nameError && !this.last_nameError
          ? (this.window = 3)
          : null;
      } else if (this.window === 3) {
        this.agree
          ? this.changeErrors(null, "agree")
          : this.changeErrors("Согласитесь с условиями использования", "agree");

        this.email && emailRegex.test(this.email)
          ? this.changeErrors(null, "email")
          : this.changeErrors("Проверьте почту", "email");

        !this.agreeError && !this.emailError ? this.signup() : null;
      }
    },
    changeErrors(errorMessage, field) {
      switch (field) {
        case "login":
          this.loginError = errorMessage;
          break;
        case "password":
          this.passwordError = errorMessage;
          break;
        case "first_name":
          this.first_nameError = errorMessage;
          break;
        case "last_name":
          this.last_nameError = errorMessage;
          break;
        case "email":
          this.emailError = errorMessage;
          break;
        case "agree":
          this.agreeError = errorMessage;
          break;
      }
    },
    signup() {
      axios
        .post(this.$store.state.api_url + "register", {
          userName: this.login,
          password: this.password,
          email: this.email,
          // first_name: this.first_name,
          // last_name: this.last_name,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          this.formError = error;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.root {
  overflow: auto;
  padding-top: 20px;
  max-height: 90vh;
  border-radius: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  border-radius: 20px !important;
}

.agree {
  font-size: 14px;
  line-height: 18px;

  &-input {
    margin-top: 3px;
    margin-right: 4px;
  }
}

.devset {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

.warning-fields {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
}

.buttons {
  gap: 12px;
}

.fields {
  border: 1px solid #e2e2e2;
  border-radius: 8px !important;
}

.signin-dialog-text-field {
  border-radius: 8px;
}

.top {
  position: relative;

  &-account {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%);

    &-icon {
      padding: 4px;
      background-color: #fff;
      border-radius: 50%;
      border-top: 1px solid #d8d8d8;

      & > * {
        font-size: 62px;
        color: black;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .warning-fields {
    font-size: 14px;
  }
}
</style>
