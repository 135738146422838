<template lang="pug">
div
  home-splash
  catalog-navigation
  main
    section.catalog
      div.custom-container.d-flex.justify-space-between.flex-column.py-10.px-6
        h1.text-center.text-uppercase Список каналов
        v-divider.mt-4.mb-6
        div(@click="showFiltersTop = !showFiltersTop").filter-btn.mb-4
          v-icon mdi-filter
          div.text-uppercase Фильтр
        div(v-if="showFiltersTop").filters-hide.pa-4.mb-6
          catalog-filter-top.filter-top
        div.filters.pa-4
          catalog-filter-bottom
          catalog-filter-top.filters-right
        div.mt-8
          catalog-channel-filter
          div.channels.channels-pd.flex-column.mt-4
            catalog-channel-card(
              v-for="(channel, index) in channels"
              :key="index"
              :channel="channel"
            )
          div.channels.channels-mobile.flex-column.mt-4
            catalog-channel-card-mobile(
              v-for="(channel, index) in channels"
              :key="index"
              :channel="channel"
            )
          v-pagination(
            v-model="page" 
            :length="pagePaginationLength" 
            :total-visible="6" 
            circle
          ).mt-10
  catalog-button
  catalog-footer
</template>

<script>
import updateTitle from "@/mixins/updateTitle";

// Components
import HomeSplash from "@/components/splashScreens/HomeSplash";
import CatalogNavigation from "@/components/Navigations/CatalogNavigation";
import CatalogFilterBottom from "@/components/CatalogFilterBottom";
import CatalogFilterTop from "@/components/CatalogFilterTop";
import CatalogButton from "@/components/CatalogButton";
import CatalogChannelFilter from "@/components/CatalogChannelFilter";
import CatalogChannelCard from "@/components/CatalogChannelCard";
import CatalogChannelCardMobile from "@/components/CatalogChannelCardMobile";
import CatalogFooter from "@/components/CatalogFooter";

export default {
  name: "CatalogView",
  components: {
    HomeSplash,
    CatalogNavigation,
    CatalogFilterBottom,
    CatalogFilterTop,
    CatalogFooter,
    CatalogButton,
    CatalogChannelFilter,
    CatalogChannelCard,
    CatalogChannelCardMobile,
  },
  mixins: [updateTitle],
  created() {
    this.updateTitle("Каталог");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      page: 1,
      indexStartForSlice: 0,
      indexEndForSlice: 8,
      showFiltersTop: false,
      channels: [
        {
          name: "Dlw full telega 18+",
          avatar:
            "https://seads.me/storage/channels/-1001662424773.jpg?nc=1689971874",
          subscribes: "357 360",
          views: "7300",
          price: "286 890",
          cpm: "7300",
          top: "1/24",
          category: "Новости 18+",
          link: "vHVyCUZxhPwxY2Qy",
        },
        {
          name: "Прямой эфир",
          avatar:
            "https://seads.me/storage/channels/-1001340766701.jpg?nc=1691366613",
          subscribes: "4 460 383",
          views: "744 600",
          price: "230 830",
          cpm: "310",
          top: "30/24",
          category: "Новости/СМИ",
          link: "vHVyCUZxhPwxY2Qy",
        },
        {
          name: "Грамотная речь | Лингвистика",
          avatar:
            "https://seads.me/storage/channels/-1001227107469.jpg?nc=1691366707",
          subscribes: "195 257",
          views: "22 600",
          price: "180 000",
          cpm: "7 965",
          category: "Познавательное, Лингвистика",
          top: "1/24",
          link: "vHVyCUZxhPwxY2Qy",
        },
      ],
    };
  },
  computed: {
    pagePaginationLength() {
      if (this.channels.length !== 8) {
        if (this.channels.length % 8 !== 0) {
          return 1 + Math.floor(this.channels.length / 8);
        } else {
          return this.channels.length / 8;
        }
      } else {
        return 1;
      }
    },
    channels_sliced() {
      return this.channels.slice(
        this.indexStartForSlice,
        this.indexEndForSlice
      );
    },
  },
};
</script>

<style scoped lang="scss">
main {
  background: #f5f5f7;
  min-height: 100vh;
  display: grid;
}

h1 {
  font-size: 38px;
}

.catalog {
  gap: 12px;
  background-color: #fafafa;
}

.filter-btn {
  display: none;
  border: 2px solid #2b393f;
  border-radius: 8px;
  padding: 4px 45px;
  font-size: 14px;
  transition: 0.3s;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #2b393f;

  & > * {
    color: #2b393f !important;
  }

  &:hover {
    background: #2b393f;
    color: #fff;
    transition: 0.3s;

    & > * {
      color: #fff !important;
    }
  }
}

.filters {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  border-radius: 15px;
  box-shadow: 0 0 8px 0 #ededed;

  & > * {
    flex: 1 1;
  }
}

.filters-hide {
  display: none;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 8px 0 #ededed;
}

.channels {
  &-mobile {
    display: none;
  }

  &-pd {
    display: flex;
  }
}

@media screen and (max-width: 1080px) {
  .filters {
    &-right {
      display: none;
    }

    &-hide {
      display: block;
    }
  }

  .filter-btn {
    display: flex;
  }

  .channels {
    &-pd {
      display: none;
    }

    &-mobile {
      display: flex;
    }
  }
}

@media screen and (max-width: 600px) {
  .custom-container {
    padding: 40px 8px !important;
  }

  h1 {
    font-size: 2rem;
  }
}
</style>
