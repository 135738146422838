<template lang="pug">
div
  main
    section(id="notfound").notfound
      v-container(style="height: 100%;").cont.py-16.px-6.pr-8
        div.main.d-flex.flex-column.align-center.justify-center
          v-icon.icon.white--text mdi-tooltip-question
          h1.text-center 404
          v-card.card
            h2.text-center Страница не найдена
            v-card.card-child.mb-10
              h3 Вы не должны были это видеть
              div Страница, которую вы ищете, больше не существует
                br
                | Вернитесь на главную страницу и помните: вы ничего не видели
            a(href="/").btn-blue.btn-pd.ma-auto
              span На главную
</template>

<script>
import updateTitle from "@/mixins/updateTitle";

export default {
  name: "NotfoundView",
  mixins: [updateTitle],
  created() {
    this.updateTitle("Страница не найдена");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
.notfound {
  background: #f5f5f7;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 6rem !important;
  position: relative;
  top: 24px;
}

h1 {
  font-size: 6rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--main-blue);
}

h2 {
  font-size: 2.25rem;
  font-weight: 700;
  text-transform: uppercase;
}

h3 {
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-pd {
  width: fit-content;
  font-size: 1rem;
  border-radius: 50px !important;
}

.main {
  height: 100%;
  position: relative;
  top: -24px;
}

.card {
  box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34) !important;
  border-radius: 50px !important;
  overflow: hidden;
  padding: 3rem 2.5rem;
  width: 100%;

  &-child {
    background-color: var(--main-blue);
    padding: 3rem 2rem;
    box-shadow: none !important;
    margin-top: 1rem;
    border-radius: 50px !important;
    text-align: center;
    color: #fff;

    & > div {
      font-size: 1.2rem;
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 1080px) {
  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  .main {
    top: -24px;
  }

  h1 {
    font-size: 6rem;
  }

  .btn-pd {
    font-size: 1.2rem;
  }

  .card-child {
    & > div {
      font-size: 1.2rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .cont {
    padding-right: 24px !important;
  }

  .card {
    border-radius: 25px !important;
    padding: 2rem 1rem;

    &-child {
      padding: 2rem 1rem;
      border-radius: 25px !important;
      margin-bottom: 24px !important;

      & > div {
        font-size: 1rem;
      }
    }
  }

  h1 {
    font-size: 4rem;
  }

  .btn-pd {
    font-size: 1rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .icon {
    font-size: 4rem !important;
    top: 12px;
  }

  .main {
    top: -12px;
  }

  h2 {
    font-size: 1.35rem;
  }
}
</style>
