<template lang="pug">
div.drawer-root
  div.drawer-container
    div.logo-wrapper
      a(href="/").logo
        v-img(src="@/assets/logo.png")
    div.main-content
      ul.pa-0
        li.d-flex.align-center
          div.left
            div.avatar
              div.user-status
              v-img(src="https://seads.me/storage/userpic/29119.png")
          div.middle
            div.user-name
              | bahtierkamalov21
            div.user-group
              | Рекламодатель
          div.right
            div.btn-open-list
              v-icon mdi-arrow-down-bold
        li.d-flex.align-center
          div.left.green
            v-icon(color="white" size="32") mdi-cash
          div.middle
            div
              | Баланс
            div
              | Пополнить
          div.right
            div.price.d-flex.align
              span 0
              v-icon mdi-currency-rub
        li.d-flex.align-center
          div.left.yellow
            v-icon(color="white" size="32") mdi-clock-time-five
          div.middle
            div
              | Холд
          div.right
            div.price.d-flex.align
              span 0
              v-icon mdi-currency-rub
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-home
          div.middle
            div
              | Каталог каналов
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-plus-circle
          div.middle
            div
              | Добавить канал
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-heart
          div.middle
            div
              | Избранное
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-advertisements
          div.middle
            div
              | Рекламный кабинет  
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-cart
          div.middle
            div
              | Мои заявки 
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-cash
          div.middle
            div
              | Платежи
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-handshake
          div.middle
            div
              | Реферальная программа
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-face-agent
          div.middle
            div
              | Поддержка
        li.d-flex.align-center
          div.left.gray
            v-icon(color="black" size="32") mdi-information
          div.middle
            div
              | О нас
</template>

<script>
export default {
  name: "ProfileDrawer",
};
</script>

<style scoped lang="scss">
.drawer {
  &-root {
    width: 68px;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    border-right: 1px solid #d8d8d8;
    transition: all 0.2s linear;

    &:hover {
      width: 308px;
      transition: all 0.2s linear;
    }
  }

  &-container {
    padding-right: 6px;
    display: flex;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
    width: 308px;

    &::-webkit-scrollbar {
      width: 0;
    }

    & > *:last-child {
      flex: 1 1;
    }
  }
}

ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.btn {
  &-open-list {
    background-color: #d8d8d8;
    padding: 4px;
    border-radius: 4px;
  }
}

.price {
  & > * {
    font-size: 14px;
    color: black;

    &:last-child {
      margin-top: 2px;
    }
  }
}

.logo {
  width: 270px;
  height: 40px;

  & > * {
    width: 100%;
    height: 100%;
  }

  &-wrapper {
    padding: 14px;
  }
}

.left {
  width: 50px;
  justify-content: center;
}

.gray {
  background-color: #d8d8d8;
}

.middle {
  flex-direction: column;
  align-items: start;
  margin-right: auto;
  justify-content: center;

  & > * {
    font-size: 12px;
    transition: all 0.2s linear;
  }
}

.right {
  width: 50px;
  justify-content: center;
}

.avatar {
  position: relative;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 50%;
}

.green {
  background-color: #09ad40;
}

.yellow {
  background-color: yellow;
}

.user {
  &-name {
    font-weight: 600;
  }

  &-status {
    width: 8px;
    height: 8px;
    position: absolute;
    bottom: 2px;
    right: 2px;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 10px;
    background-color: #09ad40;
    z-index: 1;
  }
}

li {
  height: 54px;
  background: #fafafa;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  border-left: 1px solid #dadfe4;
  border-top: 1px solid #dadfe4;
  border-bottom: 1px solid #dadfe4;
  cursor: pointer;

  &:hover {
    transition: all 0.2s linear;

    & > .middle {
      & > * {
        color: var(--main-blue);
      }
    }
  }

  & > * {
    height: 100%;
    padding: 10px 5px;
    display: flex;
    align-items: center;
  }
}

.main-content {
  background-color: #f0f0f0;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
