<template lang="pug">
v-card.card
  div.d-flex.flex-column
    div.breadcrumb
      a(href="/").text-decoration-none Главная
      span.font-weight-bold.mx-1 /
      a(href="/blog").text-decoration-none Блог
      span.font-weight-bold.mx-1 /
      span(style="color: gray;") {{ data.title }}
    div.image
      img(:src="data.image" :alt="data.image")
    div
      h2.mb-2 {{ data.title }}
      div.d-flex.justify-space-between
        div.date.d-flex-align-center
          font-awesome-icon(style="color: gray;" icon="fa-solid fa-calendar").mr-2
          | {{ data.date }}
        div.date
          | {{ data.views }}
          font-awesome-icon(style="color: gray;" icon="fa-solid fa-eye").ml-2
    div.description
      | {{ data.description }}
</template>

<script>
export default {
  name: "PostCard",
  props: {
    data: Object,
  },
};
</script>

<style scoped lang="scss">
.card {
  padding: 2rem;
  background: #fff;
  box-shadow: 0px 4px 54px rgba(166, 166, 166, 0.21) !important;
  border-radius: 20px !important;

  & > div {
    gap: 24px;
  }
}

.image {
  height: 180px;
  border-radius: 20px;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }
}

.date {
  font-size: 14px;
  font-weight: 500;
  color: gray;
}

h2 {
  font-size: 1.6rem;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .card {
    padding: 1.4rem;
  }
}
</style>
