<template lang="pug">
section(id="about").about
  v-container.container-pd.py-16
    h2 С нами легко, убедитесь сами
    div.main.d-flex.flex-wrap.align-center
      home-about-card(v-for="(card, index) in cards" :key="index" :data="card")
</template>

<script>
// Components
import HomeAboutCard from "./HomeAboutCard";

export default {
  name: "HomeAbout",
  data() {
    return {
      cards: [
        {
          title: "Огромная аудитория",
          subtitle:
            "Каждый день мы подключаем только проверенные каналы с хорошей целевой аудиторией, чтобы охватывать огромное количество пользователей. Такой подход позволяет точно подобрать нужную аудиторию для продвижения любого продукта.",
          image: require("@/assets/HomeAbout/icon-one.png"),
          image_alt: "icon-one",
        },
        {
          title: "Лояльная поддержка",
          subtitle:
            "Наша команда менеджеров и технического отдела предоставляет надежную поддержку и помощь в решении трудностей, связанных с подбором каналов и максимизацией эффективности рекламной кампании. Мы всегда готовы оперативно помочь вам достичь наилучших результатов.",
          image: require("@/assets/HomeAbout/icon-two.png"),
          image_alt: "icon-two",
        },
        {
          title: "Безопасность размещения",
          subtitle:
            "При использовании нашего сервиса вы можете быть уверены в безопасности размещения рекламы. Мы гарантируем публикацию вашей рекламы в соответствии с обговоренными условиями и предоставляем гарантию возврата средств в случае невыполнения сделки.",
          image: require("@/assets/HomeAbout/icon-three.png"),
          image_alt: "icon-three",
        },
        {
          title: "Удобный инструмент",
          subtitle:
            "На бирже доступен удобный инструмент с подробной статистикой по каналам, а также множество фильтров для удобного поиска подходящих рекламных площадок и целевой аудитории.",
          image: require("@/assets/HomeAbout/icon-four.png"),
          image_alt: "icon-four",
        },
      ],
    };
  },
  components: { HomeAboutCard },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 2.25rem;
  margin-bottom: 2.5rem;
  font-weight: 700;
}

.main {
  & > * {
    width: 50%;

    &:nth-child(even) {
      padding-left: 1.875rem;
    }

    &:nth-child(odd) {
      padding-right: 1.875rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .main {
    & > * {
      width: 100%;

      &:nth-child(even) {
        padding-left: 0;
      }

      &:nth-child(odd) {
        padding-right: 0;
      }
    }
  }
}
</style>
