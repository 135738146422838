<template lang="pug">
v-navigation-drawer(v-model="menuLocal" absolute left hide-overlay height="100vh").menu
  div(style="height: 100%;")
    div.top.px-6.py-8
      ul.pa-0.d-flex.flex-column
        li
          div(@click="openSigninDialog").btn-blue.pd-btn
            span Войти
        li
          div(@click="openSignupDialog").btn-yellow.pd-btn
            span Регистрация
    div(style="height: calc(100% - 164px);" v-if="links").pa-6.pr-4.py-8
      ul.bottom.pa-0.pr-2.d-flex.flex-column
        li(v-for="(link, index) in links" :key="index")
          a(:href="link.href").btn-blue.pd-btn
            span {{ link.text }}
</template>

<script>
export default {
  name: "HomeDrawer",
  props: {
    menu: Boolean,
    links: Array,
  },
  computed: {
    menuLocal: {
      get() {
        return this.menu;
      },
      set(value) {
        this.$emit("changeMenu", value);
      },
    },
  },
  methods: {
    openSigninDialog() {
      this.$emit("changeSigninDialog", true);
    },
    openSignupDialog() {
      this.$emit("changeSignupDialog", true);
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34) !important;
  width: 50% !important;
}

li {
  list-style: none;
}

ul {
  gap: 12px;
}

.pd-btn {
  border-radius: 4px !important;
  padding: 0.7rem 1.5rem;
}

.top {
  background: #f5f5f7;
}

.bottom {
  overflow-y: auto;
}

@media screen and (max-width: 600px) {
  .menu {
    width: 75% !important;
  }

  .pd-btn {
    font-size: 14px;
    text-align: center;
  }
}
</style>
