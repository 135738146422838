<template lang="pug">
section(id="about-us").about-us
  v-container.container-pd.py-16
    div.top.d-flex.align-center
      div.title-about-us
        span.first-text-about GrandBot
        br
        span.second-text-about Ваш помощник в мире
        br
        span.third-text-about Рекламы в телеграм каналах
      div
        div.description-segroup
          | GRANTBOT представляет собой прогрессивную торговую площадку с обширным выбором каналов, где можно выгодно и результативно приобрести рекламную площадь в Телеграме. Наша технология значительно упрощает процесс поиска и приобретения рекламы, предоставляя возможность использовать различные фильтры и статистические данные для быстрого и качественного развертывания рекламной кампании и успешного продвижения вашего продукта
    div.middle.d-flex.align-center.justify-center.mt-10
      //- div.image
      //-   img(src="@/assets/img-about-us.png" alt="about-us")
    div.bottom
      v-card.card
        div
          | С командой GRANTBOT начиная с 2023 года занимаюсь рекламой в телеграме. За это время нам удалось развить сеть из более чем 200 миллионов подписчиков, и мы продолжаем развиваться каждый день!
          br
          br
          | Клиенты, которые пользовались нашей платформой, остаются с нами надолго. С помощью нашего рекламного кабинета вы сможете создать пост и настроить свою рекламную кампанию всего за несколько кликов. Миллионы потенциальных клиентов увидят вашу рекламу, а наша команда поддержки всегда готова помочь вам настроить и выбрать целевую аудиторию по множеству критериев.
        a(
          @click="signupDialog = !signupDialog"
          href="javascript:void(0)"
        ).btn-green.btn-pd.mt-10.ma-auto
          span Рекламный кабинет
  signup-dialog(:open="signupDialog" @changeSignupDialog="setSignupDialog")
</template>

<script>
// Components
import SignupDialog from "@/components/dialogs/SignupDialog";

export default {
  name: "HomeAboutUs",
  components: { SignupDialog },
  methods: {
    setSignupDialog(value) {
      this.signupDialog = value;
    },
  },
  data() {
    return {
      signupDialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
.top {
  & > * {
    flex: 1 1;
  }
}

.first-text-about {
  color: var(--main-blue);
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.2;
}

.second-text-about {
  font-size: 2.29rem;
  text-transform: uppercase;
  font-weight: bold;
}

.third-text-about {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.82rem;
}

.btn-pd {
  width: fit-content;
  padding: 0.8rem 2rem;
  font-size: 14px;
  border-radius: 50px !important;
}

.card {
  box-shadow: 0px 4px 54px rgba(166, 166, 166, 0.21) !important;
  border-radius: 25px !important;
  padding: 3rem !important;

  & > *:first-child {
    font-weight: 500;
  }
}

.image {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.description-segroup {
  color: black;
  font-weight: 500;
}

@media screen and (max-width: 1080px) {
  .top {
    flex-direction: column;
    gap: 32px;
    align-items: start !important;
  }

  .first-text-about {
    font-size: 3rem;
  }

  .second-text-about {
    font-size: 2rem;
  }

  .third-text-about {
    font-size: 1.52rem;
  }
}

@media screen and (max-width: 600px) {
  .first-text-about {
    font-size: 2.2rem;
  }

  .second-text-about {
    font-size: 1.25rem;
  }

  .third-text-about {
    font-size: 0.99rem;
  }

  .title-about-us {
    text-align: center;
    margin: auto;
  }

  .card {
    padding: 2rem !important;
  }
}
</style>
