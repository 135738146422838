<template lang="pug">
section(id="categories").categories
  v-container.container-pd.py-16
    h2 Популярные категории
    div.main.d-flex.align-center.flex-wrap
      a(
        v-for="(category, index) in categories" 
        :key="index" 
        :href="category.link"
      ).category
        | {{ category.name }}
</template>

<script>
export default {
  name: "HomeCategories",
  data() {
    return {
      categories: [
        {
          link: "#",
          name: "Юмор",
        },
        {
          link: "#",
          name: "Познавательное",
        },
        {
          link: "#",
          name: "Новости/Сми",
        },
        {
          link: "#",
          name: "Другое",
        },
        {
          link: "#",
          name: "Пошлые",
        },
        {
          link: "#",
          name: "Женские",
        },
        {
          link: "#",
          name: "Gif и video",
        },
        {
          link: "#",
          name: "Спорт",
        },
        {
          link: "#",
          name: "Новости 18+",
        },
        {
          link: "#",
          name: "Мужское",
        },
        {
          link: "#",
          name: "Историческое",
        },
        {
          link: "#",
          name: "Музыка",
        },
        {
          link: "#",
          name: "Истории",
        },
        {
          link: "#",
          name: "Кино / Мультфильмы",
        },
        {
          link: "#",
          name: "It / приложения",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 2.25rem;
  margin-bottom: 2.5rem;
  font-weight: 700;
}

.categories {
  background: #f5f5f7;
}

.main {
  gap: 24px;

  & > * {
    flex: 0 0 30%;
  }
}

.category {
  padding: 0.938rem;
  border-radius: 12px;
  background: #fff;
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  color: #031438;
  text-transform: uppercase;
  box-shadow: 0px 4px 54px rgba(166, 166, 166, 0.21);
  transition: 0.3s;

  &:hover {
    background: var(--main-blue);
    color: #fff;
    transition: 0.3s;
    transform: scale(1.05);
  }
}

@media screen and (max-width: 600px) {
  .main {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
}
</style>
