<template lang="pug">
div
  div.d-flex.justy-space-between.align-center.main
    div.d-flex.align-center
      div(style="gap: 12px;").d-flex.align-end
        div
          div.item-lable.mb-2 Цена
          v-text-field(
            solo
            placeholder="Цена"
            prepend-inner-icon="mdi-sort-ascending"
            append-icon="mdi-currency-rub"
            hide-details
          ).catalog-field
        v-text-field(
          solo
          placeholder="до"
          prepend-inner-icon="mdi-sort-descending"
          append-icon="mdi-currency-rub"
          hide-details
        ).catalog-field
    div.d-flex.align-center
      div(style="gap: 12px;").d-flex.align-end
        div
          div.item-lable.mb-2 Охват
          v-text-field(
            solo
            placeholder="Охват, от"
            prepend-inner-icon="mdi-sort-ascending"
            hide-details
          ).catalog-field
        v-text-field(
          solo
          placeholder="до"
          prepend-inner-icon="mdi-sort-descending"
          hide-details
        ).catalog-field
  div.mt-4
    div.item-lable.mb-2 Категория
    v-select(
      solo
      v-model="category"
      :items="categories"
      item-text="name"
      prepend-inner-icon="mdi-list-box"
      hide-details
    ).catalog-field
</template>

<script>
export default {
  name: "CatalogFilterBottom",
  data() {
    return {
      price: null,
      price_before: null,
      coverage: null,
      coverage_before: null,
      category: null,
      categories: [
        {
          link: "#",
          name: "Юмор",
        },
        {
          link: "#",
          name: "Познавательное",
        },
        {
          link: "#",
          name: "Новости/Сми",
        },
        {
          link: "#",
          name: "Другое",
        },
        {
          link: "#",
          name: "Пошлые",
        },
        {
          link: "#",
          name: "Женские",
        },
        {
          link: "#",
          name: "Gif и video",
        },
        {
          link: "#",
          name: "Спорт",
        },
        {
          link: "#",
          name: "Новости 18+",
        },
        {
          link: "#",
          name: "Мужское",
        },
        {
          link: "#",
          name: "Историческое",
        },
        {
          link: "#",
          name: "Музыка",
        },
        {
          link: "#",
          name: "Истории",
        },
        {
          link: "#",
          name: "Кино / Мультфильмы",
        },
        {
          link: "#",
          name: "It / приложения",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.item-lable {
  font-weight: 700;
  font-size: 14px;
}

.main {
  gap: 24px;
}

@media screen and (max-width: 1080px) {
  .main {
    display: grid !important;
  }
}
</style>
