<template lang="pug">
div
  home-splash
  home-navigation
  main
    section(id="blog").blog.py-16
      v-container.container-pd.d-flex.justify-space-between.flex-column.pt-16
        v-row.main
          v-col
            div(style="gap: 24px;").d-flex.align-center.justify-center.flex-column
              blog-small-post(
                v-for="(post, index) in posts_sliced"
                :key="index"
                :data="post"
              )
          v-col
            blog-sidebar
        v-pagination(
          v-model="page" 
          :length="pagePaginationLength" 
          :total-visible="6" 
          circle
        ).mt-10
  home-footer
</template>

<script>
import updateTitle from "@/mixins/updateTitle";

// components
import HomeSplash from "@/components/splashScreens/HomeSplash";
import HomeNavigation from "@/components/Navigations/HomeNavigation";
import HomeFooter from "@/components/HomeFooter";
import BlogSidebar from "@/components/BlogSidebar";
import BlogSmallPost from "@/components/BlogSmallPost";

export default {
  name: "BlogView",
  components: {
    HomeSplash,
    HomeNavigation,
    HomeFooter,
    BlogSidebar,
    BlogSmallPost,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    pagePaginationLength() {
      if (this.posts.length !== 4) {
        if (this.posts.length % 4 !== 0) {
          return 1 + Math.floor(this.posts.length / 4);
        } else {
          return this.posts.length / 4;
        }
      } else {
        return 1;
      }
    },
    posts_sliced() {
      return this.posts.slice(this.indexStartForSlice, this.indexEndForSlice);
    },
  },
  data() {
    return {
      page: 1,
      indexStartForSlice: 0,
      indexEndForSlice: 4,
      posts: [
        {
          link: "why-secret",
          title: "В чём секрет успеха хорошего Telegram-канала?",
          image: "https://seads.me/storage/blog/202208/62f504ff17792.png",
          small_description:
            "TGStat недавно провели масштабное исследование, опросив множество русскоязычных пользователей мессенджера, и выяснили, что 75% из них читают новости именно в Телеграм. Данное исследование поддержали 900 различных каналов, десятки ботов, а также 7,5 тысячи групп. Четыре из пяти опрошенных пользователей регулярно читают новостные каналы, чтобы оставаться в курсе актуальных событий в России",
        },
        {
          link: "content-plan",
          title: "Контент-план для Телеграм канала",
          image: "https://seads.me/storage/blog/202208/62f50507ea2cd.png",
          small_description:
            "Большинство интернет-магазинов уже давно поняли насколько сейчас актуальны мессенджеры, и как они повышают количество и качество продаж. Однако, не все ещё осознали, что этика продаж и общения в мессенджерах отличается от других платформ. Мы предлагаем вам разобрать несколько базовых правил коммуникации с клиентами или потенциальными клиентами (это касается как Телеграма, так и Facebook",
        },
        {
          link: "why-secret",
          title: "В чём секрет успеха хорошего Telegram-канала?",
          image: "https://seads.me/storage/blog/202208/62f504ff17792.png",
          small_description:
            "TGStat недавно провели масштабное исследование, опросив множество русскоязычных пользователей мессенджера, и выяснили, что 75% из них читают новости именно в Телеграм. Данное исследование поддержали 900 различных каналов, десятки ботов, а также 7,5 тысячи групп. Четыре из пяти опрошенных пользователей регулярно читают новостные каналы, чтобы оставаться в курсе актуальных событий в России",
        },
        {
          link: "content-plan",
          title: "Контент-план для Телеграм канала",
          image: "https://seads.me/storage/blog/202208/62f50507ea2cd.png",
          small_description:
            "Большинство интернет-магазинов уже давно поняли насколько сейчас актуальны мессенджеры, и как они повышают количество и качество продаж. Однако, не все ещё осознали, что этика продаж и общения в мессенджерах отличается от других платформ. Мы предлагаем вам разобрать несколько базовых правил коммуникации с клиентами или потенциальными клиентами (это касается как Телеграма, так и Facebook",
        },
        {
          link: "why-secret",
          title: "В чём секрет успеха хорошего Telegram-канала?",
          image: "https://seads.me/storage/blog/202208/62f504ff17792.png",
          small_description:
            "TGStat недавно провели масштабное исследование, опросив множество русскоязычных пользователей мессенджера, и выяснили, что 75% из них читают новости именно в Телеграм. Данное исследование поддержали 900 различных каналов, десятки ботов, а также 7,5 тысячи групп. Четыре из пяти опрошенных пользователей регулярно читают новостные каналы, чтобы оставаться в курсе актуальных событий в России",
        },
        {
          link: "content-plan",
          title: "Контент-план для Телеграм канала",
          image: "https://seads.me/storage/blog/202208/62f50507ea2cd.png",
          small_description:
            "Большинство интернет-магазинов уже давно поняли насколько сейчас актуальны мессенджеры, и как они повышают количество и качество продаж. Однако, не все ещё осознали, что этика продаж и общения в мессенджерах отличается от других платформ. Мы предлагаем вам разобрать несколько базовых правил коммуникации с клиентами или потенциальными клиентами (это касается как Телеграма, так и Facebook",
        },
      ],
    };
  },
  watch: {
    page(newValue, oldValue) {
      if (newValue > oldValue) {
        this.indexStartForSlice += 4;
        this.indexEndForSlice += 4;
      } else {
        this.indexStartForSlice = this.indexStartForSlice - 4;
        this.indexEndForSlice = this.indexEndForSlice - 4;
      }
    },
  },
  mixins: [updateTitle],
  created() {
    this.updateTitle("Блог");
  },
};
</script>

<style scoped lang="scss">
.blog {
  background: #f5f5f7;
  min-height: 100vh;
  display: grid;
}

.container-pd {
  height: 100%;
}

.main {
  & > *:first-child {
    flex: 2;
  }
}

@media screen and (max-width: 1080px) {
  .main {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .blog {
    padding-bottom: 24px !important;
  }
}
</style>
