<template lang="pug">
v-card.card
  div(style="gap: 8px;").d-flex.align-center
    div.avatar
      img(:src="channel.avatar" alt="avatar")
    div
      div.font-weight-bold.black--text {{ channel.name }}
      div(style="font-size: 14px;").font-weight-medium Подписчиков: {{ channel.amount_subscribes }}
</template>

<script>
export default {
  name: "PostSidebarRecommendedChannel",
  props: {
    channel: Object,
  },
};
</script>

<style scoped lang="scss">
.card {
  border-radius: 15px !important;
  border: 2px solid #1d64f2;
  padding: 16px !important;
  cursor: pointer;
}

.avatar {
  border-radius: 50px;
  max-width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }
}
</style>
