<template lang="pug">
div
  v-card.card.pa-4
    div.d-flex.align-center.channel
      div(@click="router.push({ name: 'channel', params: { link: channel.link } })").avatar
        v-img(src="https://seads.me/storage/channels/-1001662424773.jpg?nc=1689971874")
      div.ml-2
        div.d-flex.align-center.channel-name 
          router-link(:to="{ name: 'channel', params: { link: channel.link } }").text-decoration-none {{ channel.name }}
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(color="mainBlue").copy-btn.ml-2
                v-icon(size="16").white--text mdi-file-delimited
            span Копировать название канала
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(color="mainBlue").copy-btn.ml-2
                v-icon(size="16").white--text mdi-link
            span Копировать ссылку на канал
        div.channel-subscribes {{ channel.subscribes }} подписчиков
    div
      div.price.d-flex.align-center.justify-center
        div {{ channel.price }}
        v-icon mdi-currency-rub
      div.views.d-flex.align-center.justify-center
        div {{ channel.views }}
        v-icon(style="margin-left: 4px;") mdi-eye
    div.views.text-center 7300
    div.category.text-center {{ channel.category }}
    div.top.text-center {{ channel.top }}
    div
      a(href="javascript:void(0);").btn-green.pd-btn
        span.d-flex.align-center
          v-icon(size="16" color="white" style="margin-right: 4px;") mdi-cart
          div(style="font-size: 14px !important;") В корзину
</template>

<script>
export default {
  name: "CatalogChannelCard",
  props: {
    channel: Object,
  },
};
</script>

<style scoped lang="scss">
.card {
  background: #fff;
  border-radius: 15px !important;
  box-shadow: 0 0 8px 0 #ededed !important;
  display: flex;
  align-items: center;

  & > * {
    flex: 1 1;

    &:first-child {
      flex: 2;
    }
  }
}

.category {
  color: #8f3faf;
  font-size: 14px;
  font-weight: 600;
}

.top {
  font-size: 14px;
  font-weight: 600;
}

.price {
  color: var(--main-green);
  font-size: 14px;
  font-weight: 600;

  & > * {
    color: var(--main-green);
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}

.pd-btn {
  padding: 0.7rem 1.2rem !important;
}

.views {
  color: var(--main-blue);
  font-size: 14px;
  font-weight: 600;

  & > * {
    color: var(--main-blue);
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}

.avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > * {
    width: 100%;
    height: 100%;
  }
}

.channel {
  &-name {
    font-weight: 600;
    color: var(--main-blue);

    & > a {
      max-width: 150px;
    }
  }

  &-subscribes {
    font-size: 12px;
    color: #a8adb9;
  }
}

.copy-btn {
  box-shadow: none !important;
  padding: 0 !important;
  min-width: 24px !important;
  height: 24px !important;
  text-align: center;
}
</style>
