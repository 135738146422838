<template lang="pug">
div.root
  profile-drawer
  div
    main.py-16.px-8
      router-view
</template>

<script>
import updateTitle from "@/mixins/updateTitle";

// components
import ProfileDrawer from "@/components/drawers/ProfileDrawer";

export default {
  name: "ProfileView",
  components: {
    ProfileDrawer,
  },
  mixins: [updateTitle],
  created() {
    this.updateTitle("Профиль");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
.root {
  padding-left: 68px;
}
</style>
