<template lang="pug">
v-card(role="banner").card
  h4 Рекомендуемые статьи
  div.main.d-flex.flex-column.mt-6
    blog-sidebar-recommended-post(
      v-for="(post, index) in recommended_posts"
      :key="index"
      :data="post"
    )
</template>

<script>
// Components
import BlogSidebarRecommendedPost from "./BlogSidebarRecommendedPost";

export default {
  name: "BlogSidebar",
  components: { BlogSidebarRecommendedPost },
  data() {
    return {
      recommended_posts: [
        {
          link: "why-secret",
          title: "В чём секрет успеха хорошего Telegram-канала?",
          image: "https://seads.me/storage/blog/202208/62f504ff17792.png",
        },
        {
          link: "content-plan",
          title: "Контент-план для Телеграм канала",
          image: "https://seads.me/storage/blog/202208/62f50507ea2cd.png",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.card {
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 54px rgba(166, 166, 166, 0.21) !important;
  border-radius: 20px !important;
}

h4 {
  font-size: calc(1.2rem + 0.3vw);
}

.main {
  gap: 18px;
}

@media screen and (max-width: 600px) {
  .card {
    padding: 1.5rem;
  }
}
</style>
