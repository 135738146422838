<template lang="pug">
div
  main
    header
      v-container.container-pd.d-flex.align-center.justify-center.flex-column
        a(
          :class="error ? 'logo-up' : null"
          href="/"
        ).logo
          img(src="@/assets/grandbot-logo.png" alt="logo")
        v-card.card
          h2.mb-5.text-center
            v-icon(
              color="#333333"
              size="32px"
              style="margin-right: 4px;"
            ) mdi-arrow-left-right
            span Восстановление пароля
          div(v-if="error").warning-fields.pa-4.mb-4
            | Введите имя пользователя или почту
          v-form(
            v-model="valid" 
            @submit.prevent="reset_password"
          )
            v-text-field(
              prepend-inner-icon="mdi-account" 
              label="Почта:" 
              outlined 
              dense
              hide-details
              :rules="[(v) => !!v || 'Email обязателен']"
            ).text-field.mb-4
            button(@click="reset_password").btn
              | Отправить запрос на восстановление пароля
</template>

<script>
export default {
  name: "ForgetPasswordView",
  data() {
    return {
      valid: false,
      error: null,
    };
  },
  methods: {
    reset_password() {
      !this.valid ? (this.error = "Введите почту для сброса пароля") : null;
    },
  },
};
</script>

<style scoped lang="scss">
.container-pd {
  height: 100vh;
  position: relative;
}

.warning-fields {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
}

.logo {
  max-width: 400px;
  position: absolute;
  width: 400px;
  top: 30%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 280px;

  &-up {
    top: 24%;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
}

.btn {
  width: 100%;
  background-color: var(--main-blue);
  color: #fff;
  padding: 10px 25px;
  transition: all 0.4s;
  border-radius: 15px;

  &:hover {
    background: #333;
    transition: all 0.4s;
  }
}

.text-field {
  border-radius: 15px;
}

h2 {
  color: #333333;
}

.card {
  padding: 20px;
  box-shadow: 0 0 13px 10px #dedede !important;
  border-radius: 30px !important;
  margin-top: 40px;
  padding-bottom: 22px;
}

@media screen and (max-width: 600px) {
  h2 {
    & > * {
      &:first-child {
        font-size: 24px !important;
      }

      &:last-child {
        font-size: 20px !important;
      }
    }
  }

  .warning-fields {
    font-size: 14px;
  }

  .btn {
    font-size: 14px;
  }

  .logo {
    top: 28%;

    &-up {
      top: 22%;
    }
  }
}
</style>
