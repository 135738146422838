<template lang="pug">
v-card.card
  div.avatar-wrapper.d-flex.align-center.justify-center
    div.avatar
      img(:src="channel.avatar" alt="avatar")
  div.d-flex.flex-column.align-center
    h1.mb-2 {{ channel.name }}
    a(href="#" target="_blank").status-btn
      | {{ !channel.private_status ? "открытый" : "приватный"  }} канал
  v-divider.my-4.mx-4
  div.data.d-flex.justify-space-between.align-start.px-6
    div.d-flex.flex-column
      div.item
        div.item-top
          v-icon mdi-account-group
          div Подписчики
        div.item-bottom
          | 321 116 подписчиков
      div.item
        div.item-top
          v-icon mdi-earth
          div Страна
        div.item-bottom
          | Россия
      div.item
        div.item-top
          v-icon mdi-timer-sand-full
          div ТОП / Лента
        div.item-bottom
          | 1/24
      div.item
        div.item-top
          v-icon mdi-timer-sand-full
          div Принятие заявки
        div.item-bottom
          | До 1 часа
    div.d-flex.flex-column
      div.item
        div.item-top
          v-icon mdi-eye
          div Охват
        div.item-bottom
          | 48 200 просмотров
      div.item
        div.item-top
          v-icon mdi-list-box
          div Категория
        div(id="category").item-bottom
          | Юмор, GIF и video
      div.item
        div.item-top
          v-icon mdi-chart-areaspline
          div CPM
        div(id="cpm").item-bottom
          | 600
  div.buttons.align-center.d-flex.pt-4
    div.d-flex.align-center.justify-center
      div 28 920
      v-icon(color="black") mdi-currency-rub
    div.bottom-btn.d-flex.align-center.justify-center
      v-icon mdi-cart
      div Купить
</template>

<script>
export default {
  name: "ChannelCard",
  props: {
    channel: Object,
  },
};
</script>

<style scoped lang="scss">
.card {
  background: #fff;
  border-radius: 15px !important;
  box-shadow: 0 0 8px 0 #ededed !important;
}

h1 {
  font-size: 28px;
  font-weight: 700;
}

.bottom {
  &-btn {
    border-radius: 0;
    border-bottom-right-radius: 8px;
    padding: 0.8rem 0.8rem;
    background: #0ab534;
    gap: 4px;
    cursor: pointer;

    & > * {
      color: #fff !important;
      font-size: 14px !important;
    }
  }
}

#cpm {
  color: var(--main-blue);
}

#category {
  color: #8f3faf;
}

.avatar {
  width: 172px;
  height: 172px;
  border-radius: 50%;
  overflow: hidden;
  top: -20px;
  position: relative;

  & > img {
    height: 100%;
    width: 100%;
  }
}

.buttons {
  & > * {
    flex: 1 1;

    &:first-child {
      & > * {
        font-size: 14px !important;
        font-weight: 500;
        color: #000000de !important;
      }
    }
  }
}

.item {
  &-top {
    display: flex;
    align-items: center;
    gap: 4px;

    & > * {
      font-size: 14px !important;
      font-weight: 600;
      color: black !important;
    }
  }

  &-bottom {
    font-size: 14px;
  }
}

.data {
  & > * {
    gap: 12px;
  }
}

.status-btn {
  border: 2px solid var(--main-blue);
  color: var(--main-blue);
  text-decoration: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 8px;
}
</style>
