import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotfoundView from "../views/NotfoundView.vue";
import BlogView from "../views/BlogView.vue";
import PostView from "../views/PostView.vue";
import CatalogView from "../views/CatalogView.vue";
import ChannelView from "../views/ChannelView.vue";
import ProfileView from "../views/ProfileView.vue";
import TermsOfUseView from "../views/TermsOfUseView.vue";
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue";
import ForgetPasswordView from "../views/ForgetPasswordView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/terms-of-use",
    name: "termOfUse",
    component: TermsOfUseView,
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: PrivacyPolicyView,
  },
  {
    path: "/blog/:slug",
    name: "post",
    component: PostView,
  },
  {
    path: "/catalog",
    name: "catalog",
    component: CatalogView,
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: ForgetPasswordView,
  },
  {
    path: "/channel/:link",
    name: "channel",
    component: ChannelView,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "*",
    name: "notfound",
    component: NotfoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
