import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import flag from "./plugins/flag";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import system from "./mixins/system";

// FontAwesomeIcons
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faArrowUp,
  faCalendar,
  faEye,
  faEarthAsia,
  faSort,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowUp, faCalendar, faEye, faEarthAsia, faSort);

Vue.use(VueApexCharts);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("ApexCharts", VueApexCharts);

Vue.config.productionTip = false;

// Methods
function login() {
  if (localStorage.getItem("login") && localStorage.getItem("password")) {
    const user_id = localStorage.getItem("user_id");

    axios
      .get(store.state.api_url + `users/${user_id}/`)
      .then((response) => {
        localStorage.setItem("login", JSON.stringify(response.data.username));
        localStorage.setItem(
          "password",
          JSON.stringify(response.data.password)
        );
        localStorage.setItem("user_id", JSON.stringify(response.data.id));
        store.commit("setUser", JSON.stringify(response.data));
      })
      .catch(() => {
        system.methods.exitSystem({ reload: true });
      });
  } else null;
}

function getChannels() {
  axios
    .get(store.state.api_url + "getChannels/")
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}
// // // // // //

login();
getChannels();

new Vue({
  router,
  store,
  vuetify,
  flag,
  render: (h) => h(App),
}).$mount("#app");
