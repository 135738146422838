<template lang="pug">
v-app
  router-view
  bottom-up(v-if="$route.path !== '/catalog' && $route.name !== 'channel'")
</template>

<script>
// Components
import BottomUp from "./components/BottomUp";

export default {
  name: "App",
  components: { BottomUp },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
:root {
  --main-blue: #1d64f2;
  --main-green: #1df259;
  --telegram: #7593d7;
  --green-dark: #00c536;
  --red: #dc3545;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  word-break: break-word;
}

.v-application {
  overflow: hidden;
  font-family: "Noto Sans", sans-serif !important;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

.btn-blue {
  background: var(--main-blue);
  padding: 0.7rem 3.5rem;
  box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34);
  text-decoration: none;
  position: relative;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.5s;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: all linear 0.5s;
    z-index: 2;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all linear 0.5s;

    &::before {
      width: 120%;
      height: 600%;
      transition: all linear 0.5s;
    }
  }

  & > span {
    position: relative;
    z-index: 4;
    color: white;
  }
}

.btn-yellow {
  background: orange;
  padding: 0.7rem 3.5rem;
  box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34);
  text-decoration: none;
  position: relative;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.5s;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: all linear 0.5s;
    z-index: 2;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all linear 0.5s;

    &::before {
      width: 120%;
      height: 600%;
      transition: all linear 0.5s;
    }
  }

  & > span {
    position: relative;
    z-index: 4;
    color: white;
  }
}

.btn-telegram {
  background: var(--telegram);
  padding: 0.7rem 3.5rem;
  box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34);
  text-decoration: none;
  position: relative;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.5s;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: all linear 0.5s;
    z-index: 2;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all linear 0.5s;

    &::before {
      width: 120%;
      height: 600%;
      transition: all linear 0.5s;
    }
  }

  & > span {
    position: relative;
    z-index: 4;
    color: white;
  }
}

.btn-red {
  background: var(--red);
  padding: 0.7rem 3.5rem;
  box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34);
  text-decoration: none;
  position: relative;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.5s;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: all linear 0.5s;
    z-index: 2;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all linear 0.5s;

    &::before {
      width: 120%;
      height: 600%;
      transition: all linear 0.5s;
    }
  }

  & > span {
    position: relative;
    z-index: 4;
    color: white;
  }
}

.btn-green {
  background: var(--main-green);
  padding: 0.7rem 3.5rem;
  box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34);
  text-decoration: none;
  position: relative;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.5s;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: all linear 0.5s;
    z-index: 2;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all linear 0.5s;

    &::before {
      width: 120%;
      height: 600%;
      transition: all linear 0.5s;
    }
  }

  & > span {
    position: relative;
    z-index: 4;
    color: white;
  }
}

.v-dialog {
  overflow: initial !important;
  box-shadow: none !important;
}

.v-navigation-drawer--absolute {
  z-index: 120 !important;
}

.v-input.catalog-field > div > div {
  box-shadow: none !important;
  border: 2px solid #ced4da;
}

@media screen and (max-width: 1080px) {
  .container-pd {
    max-width: 720px !important;
  }
}

@media screen and (max-width: 600px) {
  h2 {
    line-height: 2.45rem;
  }
}
</style>
