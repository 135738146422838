<template lang="pug">
div
  div.copyright
    | © GrandBot 2023
</template>

<script>
export default {
  name: "CopyrightFooter",
};
</script>

<style scoped lang="scss">
.copyright {
  background-color: black;
  text-align: center;
  color: white;
  font-size: 1rem;
  padding: 15px 0;
}
</style>
