<template lang="pug">
v-card.card
  h4 Рекомендуемые каналы
  div.main.d-flex.flex-column.mt-6
    post-sidebar-recommended-channel(
      v-for="(channel, index) in recommended_channels"
      :key="index"
      :channel="channel"
    )
</template>

<script>
// Components
import PostSidebarRecommendedChannel from "./PostSidebarRecommendedChannel";

export default {
  name: "PostSidebar",
  components: { PostSidebarRecommendedChannel },
  data() {
    return {
      recommended_channels: [
        {
          link: "#",
          name: "PRANK_TG | Зверолаш",
          amount_subscribes: "326 068",
          avatar:
            "https://seads.me/storage/channels/-1001227107469.jpg?nc=1691366707",
        },
        {
          link: "#",
          name: "Пекарня",
          amount_subscribes: "466 361",
          avatar:
            "https://seads.me/storage/channels/-1001418440636.jpg?nc=1691366596",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.card {
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 4px 54px rgba(166, 166, 166, 0.21) !important;
  border-radius: 20px !important;
}

h4 {
  font-size: calc(1.2rem + 0.3vw);
}

.main {
  gap: 18px;
}

@media screen and (max-width: 600px) {
  .card {
    padding: 1.5rem;
  }
}
</style>
