<template lang="pug">
nav(:class="active ? 'active' : null").pa-4
  v-container.container-pd.d-flex.align-center.justify-space-between
    a(href="/").logo
      img(src="@/assets/grandbot-logo.png" alt="logo")
    div.pc-links
      ul.pa-0.d-flex.align-center
        li(v-for="(link, index) in links" :key="index")
          div(
            v-if="link.section" 
            @click="pushToPage(link.href)"
          ) {{ link.text }}
          div(
            v-else
            @click="pushToPage(link.href)"
          ) {{ link.text }}
    div
      div(@click="openSigninDialog").btn-blue
        span Войти
      div(@click="menu = !menu" :class="menu ? 'menu-active' : null").menu-icon
        span
        span
        span
  home-drawer(
    :menu="menu" 
    :links="links" 
    @changeMenu="setMenu" 
    @changeSigninDialog="setSigninDialog" 
    @changeSignupDialog="setSignupDialog"
  )
  signup-dialog(:open="signupDialog" @changeSignupDialog="setSignupDialog")
  signin-dialog(:open="signinDialog" @changeSigninDialog="setSigninDialog")
</template>

<script>
// Components
import HomeDrawer from "@/components/drawers/HomeDrawer";
import SigninDialog from "@/components/dialogs/SigninDialog";
import SignupDialog from "@/components/dialogs/SignupDialog";

export default {
  name: "HomeNavigation",
  components: { HomeDrawer, SigninDialog, SignupDialog },
  data() {
    return {
      signinDialog: false,
      signupDialog: false,
      menu: false,
      active: false,
      links: [
        {
          section: true,
          href: "#about",
          text: "О нас",
        },
        {
          section: false,
          href: "/catalog",
          text: "Каталог",
        },
        {
          section: true,
          href: "#our-works",
          text: "Наши работы",
        },
        {
          section: false,
          href: "/blog",
          text: "Блог",
        },
        {
          section: true,
          href: "#contacts",
          text: "Контакты",
        },
      ],
    };
  },
  methods: {
    setMenu(value) {
      this.menu = value;
    },
    pushToPage(link) {
      if (link[0] == "#") {
        if (this.$route.path !== "/") {
          this.$router.push("/" + link);
          location.reload();
        } else {
          location.href = "/" + link;
        }
      } else {
        this.$router.push(link);
      }
    },
    setSignupDialog(value) {
      this.signupDialog = value;
    },
    setSigninDialog(value) {
      this.signinDialog = value;
    },
    openSigninDialog() {
      this.signinDialog = !this.signinDialog;
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      window.scrollY >= 20 ? (this.active = true) : (this.active = false);
    });
  },
};
</script>

<style scoped lang="scss">
nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transition: 0.2s;
  z-index: 200;
}

li {
  list-style: none;
  padding: 0.5rem 0.8rem;

  & > * {
    color: #031438 !important;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #0a58ca !important;
    }
  }
}

.container-pd {
  height: 54px;
}

.logo {
  width: 200px;
  height: 140px;
  position: relative;
  left: -24px;

  & > img {
    height: 100%;
    width: 100%;
  }
}

.active {
  background: #ffffff;
  box-shadow: 0px 9px 29px #c5c5c540;
  transition: 0.2s;
}

.menu {
  &-active {
    & > *:nth-child(2)::before {
      left: 20%;
    }

    & > *:last-child::before {
      left: 40%;
    }
  }

  &-icon {
    display: none;
    position: relative;
    width: 56px;
    height: 46px;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;

    & > * {
      height: 10px;
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 5px;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.2s linear;
        border-radius: 5px;
        background-color: var(--main-blue);
        box-shadow: 0px 7px 17px -1px rgba(29, 100, 242, 0.34);
      }
    }

    &:hover {
      & > *:nth-child(2)::before {
        left: 20%;
      }

      & > *:last-child::before {
        left: 40%;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .pc-links {
    display: none;
  }

  .btn-blue {
    display: none;
  }

  .menu {
    &-icon {
      display: flex;
    }
  }
}

@media screen and (max-width: 600px) {
  .menu {
    &-icon {
      width: 46px;
      height: 36px;

      & > * {
        height: 8px;
      }
    }
  }
}
</style>
