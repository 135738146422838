<template lang="pug">
section(id="channels-for-ad").channels-for-ad
  v-container.container-pd.py-16
    h2.ma-0 Актуальные каналы для рекламы
    div.subtitle
      | Мы сделали подборку актуальных каналов для вас где вы можете заказать рекламу
    div.main.d-flex.align-center.flex-wrap.justify-center.mt-8
      home-channels-for-ad-card(
        v-for="(channel, index) in channels" 
        :key="index"
        :data="channel"
      )
    a(href="/catalog").btn-green.btn-pd
      span Посмотреть другие каналы
</template>

<script>
// Components
import HomeChannelsForAdCard from "./HomeChannelsForAdCard";

export default {
  name: "HomeChannelsForAd",
  components: { HomeChannelsForAdCard },
  data() {
    return {
      channels: [
        {
          avatar:
            "https://seads.me/storage/channels/-1001227107469.jpg?nc=1691366707",
          link: "",
          views: "54 400",
          name: "PRANK_TG | Зверолаш",
          subscribes: "325 997",
          categories: [
            {
              link: "#",
              name: "Юмор",
            },
            {
              link: "#",
              name: "GIF и video",
            },
          ],
          timing: "1/24",
          cpm: 600,
          price: "32 640",
          currency: "₽",
        },
        {
          avatar:
            "https://seads.me/storage/channels/-1001227107469.jpg?nc=1691366707",
          link: "",
          views: "54 400",
          name: "PRANK_TG | Зверолаш",
          subscribes: "325 997",
          categories: [
            {
              link: "#",
              name: "Юмор",
            },
            {
              link: "#",
              name: "GIF и video",
            },
          ],
          timing: "1/24",
          cpm: 600,
          price: "32 640",
          currency: "₽",
        },
        {
          avatar:
            "https://seads.me/storage/channels/-1001227107469.jpg?nc=1691366707",
          link: "",
          views: "54 400",
          name: "PRANK_TG | Зверолаш",
          subscribes: "325 997",
          categories: [
            {
              link: "#",
              name: "Юмор",
            },
            {
              link: "#",
              name: "GIF и video",
            },
          ],
          timing: "1/24",
          cpm: 600,
          price: "32 640",
          currency: "₽",
        },
        {
          avatar:
            "https://seads.me/storage/channels/-1001227107469.jpg?nc=1691366707",
          link: "",
          views: "54 400",
          name: "PRANK_TG | Зверолаш",
          subscribes: "325 997",
          categories: [
            {
              link: "#",
              name: "Юмор",
            },
            {
              link: "#",
              name: "GIF и video",
            },
          ],
          timing: "1/24",
          cpm: 600,
          price: "32 640",
          currency: "₽",
        },
        {
          avatar:
            "https://seads.me/storage/channels/-1001227107469.jpg?nc=1691366707",
          link: "",
          views: "54 400",
          name: "PRANK_TG | Зверолаш",
          subscribes: "325 997",
          categories: [
            {
              link: "#",
              name: "Юмор",
            },
            {
              link: "#",
              name: "GIF и video",
            },
          ],
          timing: "1/24",
          cpm: 600,
          price: "32 640",
          currency: "₽",
        },
        {
          avatar:
            "https://seads.me/storage/channels/-1001227107469.jpg?nc=1691366707",
          link: "",
          views: "54 400",
          name: "PRANK_TG | Зверолаш",
          subscribes: "325 997",
          categories: [
            {
              link: "#",
              name: "Юмор",
            },
            {
              link: "#",
              name: "GIF и video",
            },
          ],
          timing: "1/24",
          cpm: 600,
          price: "32 640",
          currency: "₽",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.channels-for-ad {
  background-color: var(--main-blue);
}

.btn-pd {
  width: fit-content;
  margin: auto;
  margin-top: 2rem;
}

h2 {
  font-size: 2.25rem;
  font-weight: 700;
  color: white;
}

.main {
  gap: 24px;

  & > * {
    flex: 0 0 31.5%;
  }
}

.subtitle {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: white;
  font-weight: 500;
}

@media screen and (max-width: 1080px) {
  .main {
    & > * {
      flex: 0 0 45%;
    }
  }
}

@media screen and (max-width: 600px) {
  .main {
    & > * {
      flex: 0 0 100%;
    }
  }
}
</style>
