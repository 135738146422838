<template lang="pug">
section(id="our-works").roadmap.py-16
  v-container.px-4
    h2.text-center Наши проекты в 
      span(style="color: var(--main-blue)") TELEGRAM
  div.lines.d-flex.align-center.mt-10
    div
    div
  v-container(fluid style="max-width: 1660px; margin: auto;").container.pl-4.pr-0
    div.swiper-roadmap
      div.swiper-wrapper
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none")
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap.active-card
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-info.jpg")
            h4.mb-2
              a(href="https://t.me/GrandBot_info" target="_blank").text-decoration-none @GrandBot_info
            ul
              li 
                div.icon
                | Канал Инфо
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-update.jpg")
            h4.mb-2
              a(href="https://t.me/GrandBot_Update" target="_blank").text-decoration-none @GrandBot_Update
            ul
              li 
                div.icon
                | Канал Обновления
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-rep.jpg")
            h4.mb-2
              a(href="https://t.me/@GandBot_Rep" target="_blank").text-decoration-none @GandBot_Rep
            ul
              li 
                div.icon
                | Канал Репутация/ScamList
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-scam.jpg")
            h4.mb-2
              a(href="https://t.me/@TradeBotsList_bot" target="_blank").text-decoration-none @TradeBotsList_bot
            ul
              li 
                div.icon
                | БотоБиржа 24/7 Scam List
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-trades.jpg")
            h4.mb-2
              a(href="https://t.me/@trades_bots" target="_blank").text-decoration-none @trades_bots
            ul
              li 
                div.icon
                | Покупка - Продажа БОТОВ 
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-add.jpg")
            h4.mb-2
              a(href="https://t.me/@announcement_tradebot" target="_blank").text-decoration-none @announcement_tradebot
            ul
              li 
                div.icon
                | Подать заявку
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-trade-blue.jpg")
            h4.mb-2
              a(href="https://t.me/@tradesbots" target="_blank").text-decoration-none @tradesbots
            ul
              li 
                div.icon
                | Чаты
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-sale.jpg")
            h4.mb-2
              a(href="https://t.me/@tradesbots_bot" target="_blank").text-decoration-none @tradesbots_bot
            ul
              li 
                div.icon
                | Бот продажи исходников
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-dev.jpg")
            h4.mb-2
              a(href="https://t.me/@GrandDev_bot" target="_blank").text-decoration-none @GrandDev_bot
            ul
              li 
                div.icon
                | Бот рассылки по ботам, получения id канала/группы/пользователя/бота, приветственное сообщение\заявки
        div.swiper-slide
          div.text-center
            svg(xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none").shape-roadmap.deactive-shape
              path(opacity="0.7" d="M30 176L30 40" stroke="#1d64f2" stroke-width="2" stroke-dasharray="6 6")
              circle(cx="30" cy="30" r="30" fill="#1d64f2" class="fill-1" fill-opacity="0.2")
              circle(cx="30" cy="30" r="15" fill="#1d64f2" class="fill-2")
          v-card.card-roadmap
            div.mb-4
              div.avatar.ma-auto
                v-img(src="@/assets/bots/grandbot-info-dev.jpg")
            h4.mb-2
              a(href="https://t.me/@GrandBotDev_bot" target="_blank").text-decoration-none @GrandBotDev_bot
            ul
              li 
                div.icon
                | Бот восстановления списка подписчиков
    div(v-if="swiper_roadmap").buttons.d-flex.align-center.justify-center
      div(
        @click="clickToButton('prev')"
        :class="swiper_roadmap.realIndex > 0 ? 'button-href' : null"
      ).button.prev.mr-6
        v-icon mdi-chevron-left
      div(
        @click="clickToButton('next')"
        :class="swiper_roadmap.realIndex !== swiper_roadmap.slides.length - 1 ? 'active-button' : null"
      ).button.next
        v-icon mdi-chevron-right
</template>

<script>
import { Swiper } from "swiper";

export default {
  name: "HomeBots",
  data() {
    return {
      swiper_roadmap: null,
    };
  },
  methods: {
    clickToButton(type) {
      if (type === "next") {
        this.swiper_roadmap.slideNext();
      } else if (type === "prev") {
        this.swiper_roadmap.slidePrev();
      }

      const cards = document.querySelectorAll(".card-roadmap");
      const shapes_roadmap = document.querySelectorAll(".shape-roadmap");

      cards.forEach((item, index) => {
        item.classList.remove("active-card");

        index < this.swiper_roadmap.realIndex + 1
          ? item.classList.add("active-card")
          : null;
      });

      shapes_roadmap.forEach((item, index) => {
        item.classList.add("deactive-shape");

        index < this.swiper_roadmap.realIndex
          ? item.classList.remove("deactive-shape")
          : null;
      });
    },
  },
  mounted() {
    this.swiper_roadmap = new Swiper(".swiper-roadmap", {
      slidesPerView: 4,
      spaceBetween: 40,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
        },
        1086: {
          slidesPerView: 4,
        },
      },
    });
  },
};
</script>

<style scoped lang="scss">
.roadmap {
  overflow: hidden;
}

.avatar {
  width: 156px;
  height: 156px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

h2 {
  font-size: 2.25rem;
  margin-bottom: 2.5rem;
  font-weight: 700;
}

.button {
  border: 2px solid #72a7ff;
  width: 48px;
  border-radius: 50%;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    color: #000;
    font-size: 46px;
  }

  &-href {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--main-blue) !important;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;

      & > * {
        color: #fff !important;
      }
    }
  }
}

.lines {
  width: 100vw;
  height: 4px;

  & > *:first-child {
    width: 50%;
    height: 100%;
    background-color: var(--main-blue);
  }

  & > *:last-child {
    width: 50%;
    height: 100%;
    background-color: #434648;
  }
}

ul {
  padding: 0;
}

li {
  list-style: none;
  display: flex;
  align-items: center;
}

.active {
  &-card::before {
    opacity: 1 !important;
  }

  &-card::after {
    opacity: 1 !important;
  }

  &-button {
    background-color: var(--main-blue) !important;

    & > * {
      color: #fff !important;
    }
  }
}

.deactive-shape {
  & > *:nth-child(2) {
    fill: #767676 !important;
  }

  & > *:last-child {
    fill: #888b8e !important;
  }
}

h4 {
  font-size: 24px;
}

.icon {
  margin-right: 6px;
  width: 10px;
  border-radius: 50%;
  height: 10px;
  background-color: var(--main-blue);
}

.card-roadmap {
  padding: 24px 15px 24px 24px;
  background: #fff;
  border-radius: 20px !important;
  overflow: hidden;
  box-shadow: 0px 21px 72px rgba(170, 196, 246, 0.4) !important;
  backdrop-filter: blur(60px);
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: auto;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3);
  }

  // &::before {
  //   opacity: 0;
  //   -webkit-transition: all 0.3s ease;
  //   -moz-transition: all 0.3s ease;
  //   -ms-transition: all 0.3s ease;
  //   -o-transition: all 0.3s ease;
  //   transition: all 0.3s ease;
  //   content: "";
  //   position: absolute;
  //   width: 50px;
  //   height: 50px;
  //   top: 0px;
  //   left: 0px;
  //   border-left: 5px solid #3077f3;
  //   border-top: 5px solid #3077f3;
  // }

  // &::after {
  //   opacity: 0;
  //   -webkit-transition: all 0.3s ease;
  //   -moz-transition: all 0.3s ease;
  //   -ms-transition: all 0.3s ease;
  //   -o-transition: all 0.3s ease;
  //   transition: all 0.3s ease;
  //   content: "";
  //   position: absolute;
  //   width: 50px;
  //   height: 50px;
  //   bottom: 0px;
  //   right: 0px;
  //   border-right: 5px solid #3077f3;
  //   border-bottom: 5px solid #3077f3;
  // }
}

.swiper-roadmap {
  position: relative;
  top: -42px;
}

@media screen and (max-width: 600px) {
  .container {
    padding-right: 16px !important;
  }

  .card-roadmap {
    backdrop-filter: none;
  }
}
</style>
