<template lang="pug">
footer
  div
    v-container.container-pd.py-14
      div.main.d-flex.align-center.justify-space-between
        div
          a(href="/").logo
            img(src="@/assets/grandbot-logo.png")
        div.right
          div.help Помощь
          ul.pa-0
            li 
              a(href="/terms-of-use").text-decoration-none.black--text Пользовательское соглашение
            li 
              a(href="/privacy-policy").text-decoration-none.black--text Политика конфиденциальности
            li 
              a(href="#").text-decoration-none.black--text Связаться с нами
  copyright-footer
</template>

<script>
// Components
import CopyrightFooter from "./CopyrightFooter";

export default {
  name: "HomeFooter",
  components: { CopyrightFooter },
};
</script>

<style scoped lang="scss">
li {
  list-style: none;
}

.help {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.right {
  margin-right: 20%;
}

.logo {
  width: 200px;
  height: 140px;
  display: block;

  & > img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .main {
    flex-direction: column !important;
    gap: 52px;
  }

  .logo {
    width: 350px !important;
    height: 64px !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      height: 244px !important;
    }
  }

  .right {
    margin: 0;
    text-align: center;
  }
}
</style>
