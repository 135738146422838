import { render, staticRenderFns } from "./TermsOfUseView.vue?vue&type=template&id=6ddd39aa&scoped=true&lang=pug&"
import script from "./TermsOfUseView.vue?vue&type=script&lang=js&"
export * from "./TermsOfUseView.vue?vue&type=script&lang=js&"
import style0 from "./TermsOfUseView.vue?vue&type=style&index=0&id=6ddd39aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ddd39aa",
  null
  
)

export default component.exports